* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  color: #494949 !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
  background-color: #eaeaea !important;
}

body * {
  font-family: "Montserrat", sans-serif !important;
}

.la,
.las {
  font-family: "Line Awesome Free" !important;
}

input,
input:hover,
input:focus,
textarea,
textarea:hover,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none !important;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

/*----------------------
Style
-------------------------*/

/*-----Header------*/
.header .navbar {
  padding: 40px 0;
}

.header .navbar-brand {
  max-width: 173px;
  margin-right: 33px;
}

.header .center-part-content {
  max-width: 486px;
}

.header .center-part-content .search-box {
  height: 42px;
  max-width: auto;
}

.header .center-part-content .search-box .input-group-text {
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 0;
}

.header-search-icon-width {
  width: 357px;
}

.header .center-part-content .search-box .form-control {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-weight: 400;
  font-size: 14px;
  color: #a9a9a9;
  border: 0;
}

.header .live-btn {
  margin: 0 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .btn-live {
  min-width: 124px;
  height: 42px;
  background: linear-gradient(95.14deg, #3662ff 2.94%, #36bdff 97.82%);
  border-radius: 21px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  border: 0;
}

.header .btn-live img {
  margin-right: 10px;
  width: 24px;
}

.header .btn-wallet {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 0;
}

.header .right-part-content .user-dropdown {
  max-width: 192px;
  position: relative;
  margin-left: auto;
  display: block;
}

.header .right-part-content .btn-dropdown {
  background: #ffffff;
  border-radius: 8px;
  width: 192px;
  padding: 6px 16px 6px 6px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  color: #111111;
  display: flex;
  align-items: center;
  height: 42px;
}

.header .right-part-content .btn-dropdown .user-icon {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  overflow: hidden;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.header .right-part-content .btn-dropdown .user-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .right-part-content .dropdown-toggle::after {
  border: 0;
  background-image: url("../images/icons/ico_down-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 14px;
  height: 9px;
}

/* main section */
.main-box {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 33px;
  column-gap: 33px;
  grid-area: content;
  grid-template-areas: "left-box" "center-box" "right-box";
  grid-template-rows: auto;
}

.main-box.two-columns-grid {
  grid-template-areas: "left-box" "center-box";
}

.main-box .left-box {
  grid-area: left-box;
}

.main-box .center-box {
  grid-area: center-box;
}

.main-box .right-box {
  grid-area: right-box;
}

.main-box .sub-box-grid {
  display: grid;
  grid-area: content;
  row-gap: 32px;
  column-gap: 32px;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "left-box" "center-box" "right-box";
  grid-template-rows: auto;
}

.main-box .left-box .box-container,
.main-box .right-box .box-container {
  background: #ffffff;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  padding: 35px 30px 15px;
  margin-bottom: 25px;
  overflow: hidden;
}

.main-box .right-box .box-container {
  padding: 20px 18px;
  border-radius: 25.4032px;
  -webkit-border-radius: 25.4032px;
  -moz-border-radius: 25.4032px;
  -ms-border-radius: 25.4032px;
  -o-border-radius: 25.4032px;
}

/* side menu */
.main-box .left-box .box-container .nav {
  flex-direction: column;
}

.main-box .left-box .box-container .nav .nav-item {
  margin-bottom: 37px;
}

.main-box .left-box .box-container .nav .nav-item:last-child {
  margin-bottom: 20px;
}

.header .menu-dropdown .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 0px 86px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 229px;
  padding: 20px 23px;
  border: 0;
}

.header .menu-dropdown .dropdown-menu .nav-item:not(:last-child) {
  margin-bottom: 30px;
}

.main-box .left-box .box-container .nav .nav-link,
.header .menu-dropdown .dropdown-menu .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #acacac;
  display: flex;
  align-items: center;
  padding: 0;
  transition: all ease 300ms;
}

.main-box .left-box .box-container .nav .nav-link span,
.header .menu-dropdown .dropdown-menu .nav-link span {
  margin-left: 18px;
}

.main-box .left-box .box-container .nav .nav-link.active,
.header .menu-dropdown .dropdown-menu .nav-link.active,
.header .menu-dropdown .dropdown-menu .nav-link:hover {
  font-weight: 600;
  color: #0296dd;
}

.main-box .left-box .box-container .nav .nav-link img.img-icon,
.header .menu-dropdown .dropdown-menu .nav-link img.img-icon {
  display: inline-flex;
  /* width: 18px; */
}

.main-box .left-box .box-container .nav .nav-link img.img-icon-active,
.header .menu-dropdown .dropdown-menu .nav-link img.img-icon-active {
  display: none;
  /* width: 18px; */
}

.main-box .left-box .box-container .nav .nav-link.active img.img-icon-active,
.header .menu-dropdown .dropdown-menu .nav-link.active img.img-icon-active {
  display: inline-flex;
}

.main-box .left-box .box-container .nav .nav-link.active img.img-icon,
.header .menu-dropdown .dropdown-menu .nav-link.active img.img-icon {
  display: none;
}

/* user profile */
.main-box .left-box .box-container .box-bg-img {
  background-image: url("../images/bg/bg-img.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 108px;
}

.main-box .left-box .box-container .user-detail-box {
  padding-left: 30px;
  padding-right: 30px;
}

.main-box .left-box .box-container .user-detail-box .user-img {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  width: 74px;
  height: 74px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -38px;
  margin-bottom: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
}

.main-box .left-box .box-container .user-detail-box h4.user-name {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.main-box .left-box .box-container .user-detail-box h4.user-name small {
  font-weight: 400;
  font-size: 12px;
  display: block;
  color: #6b6b6b;
  margin-top: 5px;
}

.main-box .left-box .box-container .user-detail-box .user-follow-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 29px;
}

.main-box .left-box .box-container .user-detail-box a {
  height: 36px;
  background: #f7f7f7;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  width: 100%;
}

.main-box
  .left-box
  .box-container
  .user-detail-box
  .user-follow-details
  .follow-number {
  flex: 1;
  cursor: pointer;
}

.main-box
  .left-box
  .box-container
  .user-detail-box
  .user-follow-details
  .follow-number
  h5 {
  font-weight: 700;
  font-size: 15px;
  color: #111111;
  margin-bottom: 0px;
}

.main-box
  .left-box
  .box-container
  .user-detail-box
  .user-follow-details
  .follow-number
  span {
  font-weight: 500;
  font-size: 12px;
  color: #6b6b6b;
}

.main-box .left-box .box-container .user-detail-box .user-description {
  font-weight: 500;
  font-size: 11px;
  color: #494949;
  margin-bottom: 20px;
}

.main-box .left-box .box-container .user-detail-box .btn-profile {
  background: #f7f7f7;
  border: 1px solid #ebebeb;
  height: 36px;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* suggestions */
.main-box .right-box .box-container h5.title,
.main-box .center-box .box-container h5.title {
  font-weight: 600;
  font-size: 16px;
  color: #111111;
}

.main-box .center-box .box-container h5.title {
  padding: 10px 0 0;
}

.main-box .right-box .box-container .user-follow-lists .user-follow-item {
  padding: 15px 0 0;
}

.main-box
  .right-box
  .box-container
  .user-follow-lists
  .user-follow-item:not(:last-child) {
  border-bottom: 0.725806px solid #ededed;
  padding-bottom: 15px;
}

.user-follow-link .user-follow-name .follow-logo {
  margin-right: 10px;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.user-follow-link .user-follow-name p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #111111;
}

.follow-button .btn-follow {
  background: #3662ff;
  border-radius: 5.80645px;
  width: 56px;
  height: 21px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
}

.follow-button .btn-unfollow {
  background: grey;
  border-radius: 5.80645px;
  width: 56px;
  height: 21px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
}

.unfollow-button .btn-unfollow {
  background: grey;
  border-radius: 5.80645px;
  width: 56px;
  height: 21px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
}

/* trending feeds */
.main-box .right-box .box-container .trend-description {
  margin-top: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.trend-grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(111px, 1fr));
  grid-auto-rows: 115px;
  grid-auto-flow: dense;
  margin: 25px auto 0;
}

.trend-grid__item {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.trend-grid__item > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.trend-grid__item--hor {
  grid-column: span 2;
}

.trend-grid__item--vert {
  grid-row: span 2;
}

.trend-grid__item--lg {
  grid-column: span 2;
  grid-row: span 2;
}

.trend-grid__item .vibes-content {
  background: #f5f5f5;
  border-radius: 18px;
}

.trend-grid__item .vibes-content .vibes-icon {
  width: 57px;
  height: 54px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #ffffff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trend-grid__item.vibes {
  align-items: flex-start;
  padding: 15px;
}

.trend-grid__item .vibes-content h5 {
  font-weight: 600;
  font-size: 11px;
  color: #000000;
  margin-left: 8px;
  margin-bottom: 0;
}

/* range slider */
.vibes-progress {
  margin-top: 9px;
}

.vibes-progress .range-slider {
  width: 100%;
  position: relative;
}

.vibes-progress .range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 4.5px;
  background: #d1d1d1;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 4.5px;
  -moz-border-radius: 4.5px;
  -ms-border-radius: 4.5px;
  -o-border-radius: 4.5px;
}

.vibes-progress .range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 11.68px;
  height: 12.28px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.vibes-progress .range-slider__range::-webkit-slider-thumb:hover,
.vibes-progress .range-slider__range:active::-webkit-slider-thumb {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.vibes-progress .range-slider__range::-moz-range-thumb {
  width: 11.68px;
  height: 12.28px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.vibes-progress .range-slider__range::-moz-range-thumb:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.vibes-progress .range-slider__range:active::-moz-range-thumb {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.vibes-progress .range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #0296dd;
}

.vibes-progress ::-moz-range-track {
  background: #d1d1d1;
  border: 0;
}

.vibes-progress input::-moz-focus-inner,
.vibes-progress input::-moz-focus-outer {
  border: 0;
}

.vibes-progress .range-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8e8e8e;
  font-size: 8px;
  font-weight: 400;
  position: absolute;
  left: 0;
  right: 0;
}

/* add post */
.main-box .center-box .box-container {
  background: #ffffff;
  border-radius: 17.4194px;
  padding: 15px 20px 15px 15px;
  margin: 20px 0;
}

.center-box .user-write .user-logo {
  width: 45px;
  height: 40px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
}
.center-box .user-write .user-logo img {
  object-fit: cover;
}
.center-box .user-write .user-type {
  width: 100%;
  margin-left: 14px;
}

.center-box .user-write .user-type .form-control {
  background: #f7f7f7;
  border: 0.725806px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
  height: 37px;
}

.center-box .clip-button {
  /* width: 400px; */
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
  padding-left: 53px;
}

.center-box .btn-clip {
  background: #f7f7f7;
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: #111111;
  padding: 8px;
  width: 111px;
  margin: 0 15px;
  height: 29px;
  flex: 1;
}

.center-box .btn-clip span {
  margin-left: 10px;
}

/* post */
.center-box .post-list-box .box-container {
  padding: 30px 30px 20px;
  margin: 25px 0;
  border-radius: 25.4032px;
  -webkit-border-radius: 25.4032px;
  -moz-border-radius: 25.4032px;
  -ms-border-radius: 25.4032px;
  -o-border-radius: 25.4032px;
}

.post-list-box .post-user-detail .post-user-logo {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.post-list-box .post-user-detail h5.post-user-name {
  font-weight: 600;
  font-size: 16px;
  color: #111111;
  margin-bottom: 0;
}

.post-list-box .post-user-detail h5.post-user-name span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: block;
  color: #999999;
}

.post-list-box .post-image {
  position: relative;
  margin: 20px 0;
  box-shadow: 0px 32px 90px rgba(59, 59, 59, 0.05);
  border-radius: 0;
  width: 100%;
  height: max-content;
  overflow: hidden;
  border-radius: 18px;
}

.post-list-box .post-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.post-list-box .post-image .post-lock {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(72, 72, 72, 0.2);
  backdrop-filter: blur(25px);
  left: 0;
  right: 0;
  padding: 10px;
}

.post-list-box .post-image .post-lock-img {
  top: 0;
  bottom: 0;
  background: rgba(72, 72, 72, 0.2);
  backdrop-filter: blur(25px);
  left: 0;
  right: 0;
  padding: 10px;
}

.post-list-box .post-image .post-lock .right-button {
  position: absolute;
  right: 10px;
}

.post-list-box .post-image .post-lock .btn-teaser {
  /* width: 64px; */
  height: 26px;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  border-radius: 13px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  width: max-content;
  padding: 5px 8px;
}

.post-list-box .post-image .post-lock .btn-lock {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-weight: 600;
}

.post-list-box .post-image .post-lock-img .btn-lock {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-weight: 600;
}

.center-box .post-comments .btn-like {
  background: #ffffff;
  box-shadow: 0px 0px 11.6129px rgba(23, 31, 84, 0.05) !important;
  border-radius: 6px;
  width: 45px;
  height: 45px;
}

.center-box .post-comments .like-button small {
  font-weight: 600;
  font-size: 12px;
  color: #313131;
  text-align: center;
  margin-top: 8px;
}

.center-box .post-comments .btn-donate {
  width: 104px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 0px 11.6129px rgba(23, 31, 84, 0.05) !important;
  border-radius: 6px;
  color: #3662ff;
  font-weight: 600;
  font-size: 12px;
}

.upload-story-content button {
  background: linear-gradient(90.87deg, #3662ff 0%, #36bdff 100%);
  border-radius: 0px 0px 30px 30px;
  height: 53px;
  width: calc(100% + 2px);
  bottom: 4px;
  border: 1px solid #3677ff;
  margin-left: -1px;
}

.center-box .comment-description {
  margin-top: 20px;
}

.center-box .comment-description p {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.center-box .comment-description p span {
  color: #3662ff;
}

.center-box .comment-description p.post-time {
  font-weight: 600;
  font-size: 11px;
  color: #999999;
}

/* story slider */
.center-box .swiper {
  position: relative;
}

.center-box .story-slider {
  overflow: hidden;
  position: relative;
}

.story-slider .right-side-blur {
  position: absolute;
  background-color: #eaeaea;
  width: 48px;
  height: 105px;
  right: -8px;
  z-index: 2;
  filter: blur(5.5px);
  -webkit-filter: blur(5.5px);
  top: -10px;
}

.center-box .story {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.center-box .story .profile {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-box .story .profile.add-story {
  border: 0.725806px solid #0085c5;
  background-color: #fff;
}
.center-box .story .profile.add-story img {
  object-fit: cover;
}
.story-slider .swiper-button-next,
.story-slider .swiper-button-prev {
  box-shadow: 0px 0px 11.6129px rgb(0 0 0 / 15%);
  width: 22px;
  height: 22px;
  border-radius: 50px;
}

/* .story-slider .swiper-button-next {
  right: 26px;
  left: auto;
  bottom: 35px;
  top: auto;
} */
.story-slider .swiper-button-next:after,
.story-slider .swiper-button-prev:after {
  font-size: 0;
}

.center-box .story .profile .profile-add {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  background: linear-gradient(180deg, #36bdff 0%, #0085c5 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-box .story .profile .profile-add img {
  width: 20px;
  height: 20px;
}

.center-box .story .profile img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  object-fit: cover;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}

.center-box .story .title {
  text-align: center;
  padding: 5px 0;
  font-weight: 500;
  font-size: 11px;
  color: #3b3b3b;
  white-space: nowrap;
}

/* login page */
section.login-section.signup-section {
  padding: 108px 0px;
}

.login-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.login-section .footer {
  position: absolute;
  bottom: 30px;
}

.login-box .login-title {
  font-weight: 600;
  font-size: 24px;
  text-align: center;

  color: #0296dd;
}

.login-box .login-right {
  margin-left: -20px;
  position: relative;
}

.login-box .login-right .login-form {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  width: 356px;
  padding: 22px 30px 25px;
  margin-bottom: 10px;
}

.login-box button,
.login-box button:hover,
.login-box button:focus {
  background: #0296dd;
  border-radius: 8px;
  border: 1px solid #0296dd;
  color: white;
}

.login-box .form-group.error-msg input {
  border: 1px solid #f20c0c !important;
}

.login-box .form-group.error-msg .text-danger {
  font-weight: 400;
  font-size: 11px;
  line-height: 142.5%;
  /* identical to box height, or 16px */
  color: #f20c0c;
}

.login-box .login-right .login-form .logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box .login-right .login-form form {
  margin-top: 40px;
}

.login-box .login-right .login-form form .form-group {
  margin-bottom: 11px;
  position: relative;
}

.login-box .login-right .login-form form .form-group img {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-box .login-right .login-form form .form-control {
  background: #f1f1f1;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  padding: 16px 12px 15px;
  height: 51px;
  border: 0;
}

.login-box .login-right .login-form form .input-group-text {
  background: #f1f1f1;
  border: 0;
}

.login-box .login-right .login-form form .forgot-link {
  margin-top: 8px;
  text-decoration: underline !important;
}

.login-box .login-right .forgot-link,
.forgot-link {
  text-decoration-line: underline !important;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  margin-left: 11px;
  align-items: end;
  margin-bottom: 2px;
  color: #0296dd;
}

.login-box .login-right .signup-link a {
  color: #0296dd;
  text-decoration: underline !important;
}

.login-box .login-right .signup-link {
  margin-top: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.btn-login {
  background: #0296dd;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  height: 37px;
}

.login-box .login-right p.get-app,
.footer p {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-bottom: 10px;
}

.login-box .login-right .group-button .btn-app {
  border-radius: 6px;
  width: 103px;
  height: 31px;
  padding: 0;
}

.footer p {
  color: #787878;
}

.footer .form-select {
  color: #787878;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  font-weight: 400;
  background-image: url(../images/icons/ico_downarrow.png);
  background-size: 12px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

/* signup section */
.signup-section .login-right .login-form form .form-group {
  margin-bottom: 25px;
}

/* forgot password */
.forgot-psw .or-line {
  font-weight: 500;
  font-size: 11px;
  color: #a9a9a9;
  position: relative;
  text-align: center;
  margin-top: 26px;
}

.forgot-psw .or-line::after,
.forgot-psw .or-line::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 134px;
  background-color: #e2e2e2;
  top: 0;
  bottom: 0;
  display: flex;
  margin: auto;
}

.forgot-psw .or-line::after {
  right: 0;
}

.forgot-psw .or-line::before {
  left: 0;
}

.forgot-psw .login-box .login-right .signup-link {
  margin-top: 17px;
}

section.login-section.forgot-psw h2.login-title {
  margin: 0;
}

section.login-section.forgot-psw form {
  margin-top: 50px;
}

section.login-section.forgot-psw
  .login-box
  .login-right
  .login-form
  form
  .form-group {
  margin-bottom: 30px;
}

/* otp verify */
.otp-section .login-right .login-form {
  width: 420px;
}

.otp-section .login-box .login-right .signup-link {
  /* margin-top: 30px; */
  font-weight: 500;
  font-size: 14px;
  line-height: 142.5%;
  color: #707070;
}

.otp-section .login-box .login-form .otp {
  width: 50px;
  margin: 0 5px;
  text-align: center;
}

.otp-section .login-box .login-form form .form-group {
  margin-bottom: 30px;
}

.otp-section input,
.otp-section input:hover,
.otp-section input:focus {
  width: 50px !important;
  height: 51px;
  background: #f1f1f1;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: none;
}

.otp-section span {
  display: none;
}

.otp-section .form-group > div {
  display: flex;
  gap: 10px;
}

/* other profile content */
.other-profile-container {
  max-width: 808px;
  width: 100%;
}

.other-profile-container
  .center-box
  .post-list-box
  .post-item:first-child
  .box-container {
  margin: 0px 0 25px;
}

.other-profile-container .box-container {
  /* background-color: #fff; */
  border-radius: 35px;
  padding: 30px 22px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  margin-bottom: 30px;
}

.other-profile-container .profile-content .profile-bg {
  background-image: url("../images/bg/bg-img1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 245px;
  box-shadow: 0px 19px 32px rgba(72, 72, 72, 0.1);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  overflow: hidden;
}

.other-profile-container .profile-content .profile-more-detail {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.other-profile-container .profile-content .profile-more-detail .profile-more {
  position: absolute;
  left: auto;
  right: 24px;
  top: 25px;
}

.other-profile-container .profile-content .profile-logo {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  margin: -62.5px auto 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.other-profile-container .profile-content .profile-logo img {
  object-fit: cover;
}
.other-profile-container h3.profile-title {
  font-weight: 700;
  font-size: 32px;
  color: #111111;
  margin-bottom: 29px;
}

.other-profile-container h3.profile-title span {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #6b6b6b;
}

.other-profile-container p.profile-description {
  max-width: 534px;
  font-weight: 500;
  font-size: 16px;
  color: #494949;
  margin: 0 auto 20px;
}

.other-profile-container a.profile-link {
  text-decoration-line: underline;
  color: #0296dd;
  font-weight: 400;
  font-size: 16px;
}

.other-profile-container .social-link {
  margin: 35px 0 38px;
}

.other-profile-container .social-link ul {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-profile-container .social-link ul li.social-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.other-profile-container .social-link ul li.social-item:not(:last-child) {
  margin-right: 26.26px;
}

.other-profile-container .profile-follow {
  margin-bottom: 44px;
}

.other-profile-container .profile-follow .follow-number {
  margin: 0 47px;
  width: 80px;
  cursor: pointer;
}

.other-profile-container .profile-follow .follow-number h5 {
  font-weight: 800;
  font-size: 24px;
  color: #111111;
  text-align: center;
  margin-bottom: 0;
}

.other-profile-container .profile-follow .follow-number span {
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
}

.other-profile-container .profile-content .follow-button .btn-follow,
.other-profile-container .follow-button .btn-message {
  width: 190px;
  height: 34px;
  font-weight: 600;
  font-size: 15px;
}

.other-profile-container .follow-button .btn-message {
  background: #eaeaea;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin-left: 23px;
}

.other-profile-container .box-container.subscription {
  padding: 20px 35px 30px;
}

.other-profile-container .subscription .subscription-title {
  font-weight: 600;
  font-size: 16px;
  color: #797979;
  margin-bottom: 25px;
  text-align: center;
}

.other-profile-container .subscription-pricelist .price-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 25px 40px;
  margin-bottom: 30px;
  height: 75px;
}

.other-profile-container .price-content .price-label,
.other-profile-container .price-content .price-number {
  font-weight: 600;
  font-size: 20px;
  color: #111111;
}

.other-profile-container .price-content .price-number {
  font-weight: 700;
  color: #3662ff;
}

.other-profile-container .subscrib-button .btn-subscribe {
  background: linear-gradient(90.86deg, #3662ff 0.6%, #36bdff 101.55%);
  border-radius: 20px;
  color: #f1f1f1;
  font-weight: 600;
  font-size: 20px;
  height: 75px;
  max-width: 738px;
  width: 100%;
}

/* notification page */
.main-box .center-box .box-container.notification-container {
  padding: 24px 0 25px;
  margin: 0;
  min-height: 569px;
  overflow: auto;
}

/* .main-box .center-box .box-container.notification-container::-webkit-scrollbar {
  width: 0px;
}
.main-box .center-box .box-container.notification-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.main-box .center-box .box-container.notification-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
} */
.notification-content .notification-lists .notification-item {
  padding: 20px 23px 0;
}

.notification-content .notification-lists .notification-item:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
}

.notification-content
  .notification-lists
  .notification-item
  .notification-link {
  display: flex;
  align-items: center;
}

.notification-content
  .notification-lists
  .notification-item
  .notification-link
  .notification-logo {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-right: 15px;
  overflow: hidden;
}

.notification-content
  .notification-lists
  .notification-item
  .notification-link
  .notification-comment
  p {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.notification-content
  .notification-lists
  .notification-item
  .notification-link
  .notification-comment
  p
  span.text-blue {
  color: #0296dd;
}

.notification-content
  .notification-lists
  .notification-item
  .notification-link
  .notification-comment
  small {
  font-weight: 500;
  font-size: 14px;
  color: #9f9f9f;
  margin-top: 4px;
}

/* explore page */
.explore-container .box-container {
  background-color: #ffffff;
  padding: 30px 19px;
  border-radius: 25.4px;
  -webkit-border-radius: 25.4px;
  -moz-border-radius: 25.4px;
  -ms-border-radius: 25.4px;
  -o-border-radius: 25.4px;
}

.explore-container .box-container .explore-grid {
  grid-auto-rows: 250px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 0;
}

.explore-container .box-container .explore-grid .trend-grid__item {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

/* settings pages */
.setting-container {
  margin-bottom: 62px;
}

.setting-container .box-container {
  border-radius: 25.4032px;
  -webkit-border-radius: 25.4032px;
  -moz-border-radius: 25.4032px;
  -ms-border-radius: 25.4032px;
  -o-border-radius: 25.4032px;
  padding: 0;
  margin-bottom: 0;
  background-color: #ffffff;
}

.setting-container .setting-grid-box {
  display: grid;
  grid-area: content;
  row-gap: 0;
  column-gap: 0;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "tabs-box" "tabs-content";
  grid-template-rows: auto;
}

.setting-container .setting-grid-box .setting-tabs {
  grid-area: tabs-box;
  /* border-right: 2px solid #e2e2e2; */
}

.setting-container .setting-grid-box .setting-tabs .nav-link,
.setting-container .setting-grid-box .setting-tabs .nav-link:hover,
.setting-container .setting-grid-box .setting-tabs .nav-link:focus {
  border: none !important;
}
.nav-item-not-active {
  border-right: 3px solid #dee2e6 !important;
}
.setting-container .setting-grid-box .setting-tabs .nav-link.active {
  /* border-right: 4px solid #0296dd !important; */
}

.setting-container .setting-grid-box .setting-tabs .nav-link:hover {
  color: #0296dd !important;
}

.setting-container .setting-grid-box .settings-tab-content {
  grid-area: tabs-content;
  padding: 25px 30px 40px;
}

/* verticle tab */

.settings-main {
  width: 100%;
}

.settings-main .tabs_wrapper {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background: transparent;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 25px;
}

.settings-main .profile-box .save_btn {
  background-color: #0296dd;
  width: 160px;
  border: none;
  height: 40px;
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin: 20px auto 0;
}

.settings-main ul li {
  margin: 0;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 31px;
  color: white;
  text-align: left;
  font-weight: bold;
}

/* end verticle tab */

/* start top section */

.settings-main .profile-content .profile-bg {
  background-image: url("../images/bg/bg-img1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 185px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.settings-main .profile-content .profile-bg label {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 39px;
}

.settings-main .profile-content .profile-bg input {
  display: none;
}

.settings-main .profile-content .profile-bg .changebtn {
  color: #000000;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 40px rgb(140 140 140 / 15%);
  border-radius: 10px;
  padding: 7px 15px;
  font-weight: 600;
  height: 39px;
  min-width: 204px;
}

.settings-main.profile-content .profile-more-detail {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.settings-main .profile-content .profile-more-detail .profile-more {
  position: absolute;
  left: auto;
  right: 24px;
  top: 25px;
}

.settings-main .profile-content .profile-logo {
  width: 95px;
  height: 95px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  margin: -35px auto 0px;
}

.settings-main .profile-content .profile-logo img {
  border-radius: 50%;
  object-fit: cover !important;
  cursor: pointer;
}

.settings-main .profile-content .upload-profile-pic input {
  display: none;
}

.settings-main .profile-content .profile-link {
  color: #0296dd;
  display: inline-block;
  margin-top: 20px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
}

/* end top section */

/* start input forms */

.settings-main .input-main {
  padding-top: 30px;
  margin: 0 !important;
}

.settings-main .input-main .input-part {
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.settings-main .input-main .input-part-error {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
}

.settings-main .input-main .form-control,
.settings-main .input-main .form-select {
  padding: 10px 10px 10px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  color: #868686;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
}

.settings-main .input-group > .form-control {
  border-left: 0 !important;
  margin-left: -6px !important;
  padding-left: 15px;
}

.settings-main .input-main input::placeholder,
.addresstext::placeholder {
  color: #868686;
  font-size: 13px;
  font-weight: 500;
}

.settings-main .input-main .addresstext {
  height: auto;
}

.settings-main .input-main .urlinput {
  position: relative;
}

.settings-main .urlinput input {
  padding-left: 50px;
}

.settings-main .input-main .socialimg {
  background: #0296dd;
  width: 35px;
  height: 40px;
  border-radius: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  border: 0;
  z-index: 2;
}

.settings-main .input-main .link {
  background-color: #b8b8b8;
}

.settings-main .input-main .linkdin {
  background-color: #0072b1;
}

.settings-main .input-main .twitter {
  background-color: #1da1f2;
}

.settings-main .input-main .insta {
  background: linear-gradient(
    225deg,
    #fbe18a -10.22%,
    #fcbb45 21.66%,
    #f75274 47.23%,
    #d53692 69.77%,
    #8f39ce 102.77%,
    #5b4fe9 142.65%
  );
}

.settings-main .input-main .facebook {
  background-color: #3b5998;
}

/* end input forms */

.settings-main .nav {
  display: block;
  flex-wrap: nowrap;
  height: 100%;
  border-bottom: 0;
}

.settings-main .accordion-item {
  border: none !important;
}

.settings-main .nav-link {
  width: 100%;
  border: none;
  text-align: left;
  padding: 0px 0px 0px 30px;
  font-size: 16px;
  color: #7c7c7c;
  font-weight: 500;
  height: 60px;
  line-height: 120%;
}

.settings-main .nav-item {
  padding: 0;
  /* margin-right: -3px; */ /*pending  */
}

.settings-main .accordion-body {
  padding: 0;
  background-color: transparent;
}

.settings-main .accordion-collapse {
  background-color: transparent;
}

.settings-main .accordion-item {
  background-color: transparent;
}

.settings-main .accordion {
  background-color: transparent;
}

.settings-main .tabs_wrapper {
  background-color: #ffffff;
  border-radius: 25px;
}

.settings-main .nav-tabs .nav-link.active {
  /* background-color: transparent;
  color: #0296dd !important;
  border-right: 4px solid #0296dd !important;
  border-radius: 0 !important;
  font-weight: 600; */
}

.settings-main .accordion-button {
  background-color: transparent;
  font-weight: 600;
  font-size: 18px;
  color: #7c7c7c;
  border: 1px solid#7C7C7C;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  padding: 0.75rem 1.25rem;
}

.settings-main .accordion-button:not(.collapsed) {
  color: #0296dd;
  box-shadow: none;
  font-weight: 600;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #0296dd;
}

.settings-main .accordion-button:focus {
  box-shadow: none;
}

.settings-main h2.setting-title {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 35px;
  text-align: center;
}

/* blacklist css */
.blacklist-content .input-group-text,
.blacklist-content .form-control {
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  height: 45px;
}

.search-list-content {
  border: none;
}

.search-list-content ul {
  background: rgb(249, 249, 249);
  padding: 0px !important;
  border-radius: 8px;
  max-height: 250px;
  overflow: auto;
}

.search-list-content ul::-webkit-scrollbar {
  width: 0px;
}

.search-list-content ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.search-list-content ul::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.search-list-content button {
  background: transparent;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #0296dd;
  border: 1px solid #0296dd;
  padding: 4px 10px;
  min-width: 100px;
}

.search-list-content ul li p {
  color: black;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}

.search-list-content ul li {
  border-bottom: 1px dashed #e7dcdc;
  max-width: 100%;
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.blacklist-content .search-box {
  position: relative;
  margin-bottom: 40px;
}

.blacklist-content .btn-unblock {
  background: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  height: 26px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 110.5%;
  color: #525252;
}

.blacklist-content .search-box input {
  height: 45px;
  border: 1px solid #e2e2e2 !important;
  border-radius: 12px;
  width: 100%;
  padding: 5px 12px 5px 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
}

.blacklist-content .search-box img {
  position: absolute;
  left: 15px;
  top: 12px;
}

.blacklist-content .blacklist-item {
  margin-bottom: 25px;
}

.blacklist-link .blacklist-user span {
  color: #111111;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
}

.blacklist-setting-image {
  position: relative !important;
  left: 0px !important;
  top: -3px !important;
}

/* .blacklist-user-image img {
  margin-top: -16px;
    margin-right: 19px;
    margin-left: -15pxg;
} */
.blacklist-link .blacklist-user span span {
  font-size: 20px;
  margin-left: 0px;
}

.change-psw-form label {
  font-size: 16px;
  font-weight: 600;
  color: #111111;
  margin-bottom: 4px;
}

.change-psw-form button,
.change-psw-form button:hover,
.change-psw-form button:focus {
  width: 160px;
  height: 40px;
  background: #0296dd;
  border: 1px solid #0296dd;
  border-radius: 8px;
}

.change-psw-form .btn-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-psw-form .input-group-text {
  background-color: #f1f1f1;
  border: 0;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.change-psw-form .current-psw .input-group-text {
  border: 1px solid #adadad;
}

.settings-main .change-psw-form .input-group > .form-control {
  background: #f1f1f1;
  border-radius: 12px;
  font-size: 15px;
  color: #363636;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  border: 0;
  height: 50px;
}

.settings-main .change-psw-form .current-psw .input-group > .form-control {
  border-left: 1px solid #adadad !important;
  border: 1px solid #adadad;
}

.change-psw-form .form-group {
  max-width: 400px;
  margin: 0 auto;
}

.change-psw-form .forgot-link {
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 10px;
}

.change-psw-form .form-group {
  margin-bottom: 40px;
}

.change-psw-form .current-psw {
  margin-bottom: 50px;
}

.change-psw-form .btn-login {
  width: 160px;
  margin: 0 auto;
}

/* language tab */
.language {
  margin-top: 25px;
}

.language .language-item {
  margin-bottom: 30px;
}

.language .language-item .language-link {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #838383;
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 85px;
  max-width: 529px;
}

.language .language-item .language-link:hover {
  color: #838383;
}

.language .language-item .language-link.active {
  background-color: #e9f8ff;
}

.language .language-item .language-link p span.d-block {
  font-size: 24px;
  font-weight: 600;
  color: #111111;
}

.language .language-item .language-link.active p span.d-block {
  color: #0296dd;
}

.language .language-item .language-link .check-sign {
  display: none;
}

.language .language-item .language-link.active .check-sign {
  display: block;
}

/* referral code */
.referral-box {
  background: #f0f0f0;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.referral-box h2 {
  color: #0296dd;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.referral-box .referral-img {
  max-width: 271px;
  margin: 0 auto;
}

.referral-box .referral-codebox {
  width: 219px;
  margin: 30px auto;
  height: 48px;
  background: #ffffff;
  border: 1px dashed #adadad;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.referral-box .referral-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-box .referral-button .btn-code {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.referral-box .referral-button .btn-code.share-btn {
  margin-left: 25px;
}

.referral-box .referral-button .btn-code img {
  width: 20px;
}

/* payout */
.payout-content .my-balance {
  background: #f0f0f0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  height: 48px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payout-content .my-balance p {
  color: #6b6b6b;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.payout-content .my-balance h4 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  color: #6b6b6b;
}

.payout-content .amount-balance {
  height: 104px;
  margin-top: 40px;
  margin-bottom: 20px;
  background: linear-gradient(95.61deg, #3662ff -2.46%, #36bdff 103.59%);
  padding: 20px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.payout-content .amount-balance .dolor-img {
  width: 65px;
  height: 65px;
  border-radius: 100%;
  background-color: #efc75e;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.payout-content .amount-balance {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.payout-content .amount-balance input {
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 110.5%;
  color: #f0f0f0;
  border-bottom: 1px solid #ffffff;
  padding: 8px 0px;
}

.payout-content .amount-balance input::placeholder {
  color: #f0f0f0;
}

.payout-content .amount-balance button {
  width: 110px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0296dd;
  border-radius: 4px;
}

.payout-content .amount-balance .dolor-input {
  margin-left: 20px;
}

.payout-content .amount-balance .dolor-input .form-control {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  font-weight: 500;
  font-size: 14px;
  width: 247px;
  padding-bottom: 9px;
  padding-left: 0;
  color: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.payout-content .amount-balance .dolor-input .form-control::placeholder {
  color: #fff;
}

.payout-content .amount-balance .payout-button .btn-payout {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #0296dd;
  font-weight: 600;
  font-size: 16px;
  width: 110px;
  height: 42px;
}

.payout-content .dolor-text {
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  text-align: center;
}

.payout-history {
  margin-top: 50px;
  text-align: center;
}

.payout-history ul {
  padding: 0;
}

.payout-history h4 {
  font-weight: 600;
  font-size: 16px;
  color: #838383;
  margin-bottom: 35px;
}

.payout-history .payout-item {
  height: 84px;
  background: #f0f0f0;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 20px;
}

.payout-history ul li.payout-item .btn-pending {
  background-color: #efc75e;
  color: #111111;
  min-width: 101px;
  height: 30px;
  background: #efc75e;
  border-radius: 6px;
  border: 1px solid #efc75e;
  font-weight: 600;
  font-size: 14px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #111111;
}

.payout-history ul li.payout-item .btn-success {
  background-color: Green;
  color: #ffffff;
  min-width: 101px;
  height: 30px;
  background: Green;
  border-radius: 6px;
  border: 1px solid Green;
  font-weight: 600;
  font-size: 14px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.payout-history ul li.payout-item .btn-danger {
  background-color: Green;
  color: #ffffff;
  min-width: 101px;
  height: 30px;
  background: #c30a0a;
  border-radius: 6px;
  border: 1px solid #c30a0a;
  font-weight: 600;
  font-size: 14px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.payout-history .left-box h6 {
  font-weight: 600;
  font-size: 32px;
  line-height: 110.5%;
  /* or 35px */
  display: flex;
  align-items: center;
  color: #111111;
  margin-bottom: 5px;
}

.payout-history .left-box p {
  font-weight: 500;
  font-size: 12px;
  line-height: 110.5%;
  /* or 13px */
  display: flex;
  align-items: center;
  color: #838383;
  margin: 0;
}

.payout-history ul li.payout-item .btn-succedd {
  background-color: #059d14;
  border: 1px solid #059d14;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

/* verify tab */
.verification-content h3 {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #0296dd;
}

.status {
  margin-top: 25px;
  margin-bottom: 8px;
  padding: 18px 30px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}

.varificsation-reject-message {
  color: red;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 50px;
  padding-top: 5px;
}

.status .status-title img {
  width: 44px;
}

.status .status-title span {
  font-weight: 700;
  font-size: 20px;
  color: #111111;
  margin-left: 15px;
}

.btn-applied {
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  min-width: 160px;
  height: 54px;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #111111 !important;
}

.document-verification {
  margin-top: 30px;
  background: #f0f0f0;
  border-radius: 13px;
  padding: 30px 51px;
}

.blacklist-content .search-box input {
  height: 45px;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 100%;
  padding: 5px 12px 5px 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #a9a9a9;
}

.blacklist-content .search-box img {
  position: absolute;
  left: 15px;
  top: 12px;
}

.document-verification .select-document button.btn.btn-select {
  width: 96px;
  height: 29px;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 110.5%;
  /* or 15px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #7e7e7e;
  background-size: cover;
}

.document-verification ul li::before {
  content: "";
  position: absolute;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50%;
  left: -15px;
  top: 14px;
}

.document-verification ul li {
  position: relative;
}

.verification-btn .btn-login,
.verification-btn .btn-login:hover,
.verification-btn .btn-login:focus {
  width: 160px;
  height: 40px;
  background: #0296dd;
  border: 1px solid #0296dd;
  border-radius: 8px;
}

.upload-document .form-check input {
  height: 18px;
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  border: 2px solid #0296dd;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

.upload-document .form-check input:checked {
  /* background-color: #0296dd; */
  height: 18px;
  width: 18px;
  border: 2px solid #0296dd;
}

.upload-document .form-check input:checked::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background: #0296dd;
  top: 2px;
  left: 2px;
  border-radius: 50%;
}

.document-verification h4 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #111111;
  margin-bottom: 25px;
}

.document-verification .select-document {
  background: #c5c5c5;
  border-radius: 12px;
  max-width: 100%;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-position: center;
}

.btn-select {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #7e7e7e;
}

.upload-document {
  margin-top: 30px;
}

.upload-document p {
  font-weight: 500;
  font-size: 16px;
  color: #4b4b4b;
  text-align: center;
  margin-bottom: 43px;
}

.upload-document .form-check {
  max-width: 100%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.upload-document .form-check:last-child {
  margin-bottom: 0px;
}

.upload-document .form-check-label {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.upload-document .form-check-input {
  border: 2px solid #0296dd;
  background-color: transparent;
  width: 18px;
  height: 18px;
}

.upload-document .form-check-input:checked {
  background-color: #0296dd;
  border-color: #0296dd;
}

.document-verification ul {
  margin-bottom: 30px;
}

.document-verification ul li {
  list-style: circle;
  padding: 0;
  margin-bottom: 15px;
}

.document-verification ul li p {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 120%;
}

.verification-btn {
  margin-top: 40px;
}

.verification-btn .btn-login {
  width: 160px;
  height: 40px;
}

/* message */
.messages-container {
  overflow: hidden;
}

.messages-container .chat-search input {
  background: #e9e9e9;
  border-radius: 22px;
  height: 30px;
  padding: 5px 20px 5px 30px !important;
}

.messages-container .chat-search img {
  position: absolute;
  top: 8px;
  left: 10px;
}

.messages-container .chat-search {
  position: relative;
}

.setting-container .messages-container .setting-tabs {
  padding: 20px;
}

.post-user-control {
  padding: 13px;
  cursor: pointer;
}

.setting-tabs .post-user-control-active,
.setting-tabs .post-user.post-user-control:hover {
  background: #e9f8ff;
  border-radius: 12px;
}

.setting-tabs {
  border-right: 2px solid #e2e2e2;
}

.post-user-control .post-user-logo {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 15px;
}

.post-user-control .post-user-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #3b3b3b;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.post-user-control .post-user-name span {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #676767;
}

.post-user-control .text-msg {
  font-size: 12px;
  line-height: 15px;
  color: #3b3b3b;
  margin: 5px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.post-user-control .text-msg .badge {
  position: absolute;
  right: 0;
  background: #0296dd;
  border-radius: 7.5px;
}

.post-user-control .text-msg b {
  font-weight: 600;
}

.post-user-control .col {
  max-width: calc(100% - 60px);
  padding: 0;
}

.settings-tab-content .post-user-control {
  padding: 20px;
}

.message-box {
  margin: 10px 0;
}

.message-box .content {
  background: #efefef;
  border-radius: 13px 13px 13px 0px;
  padding: 15px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #444649;
  max-width: 289px;
}

.message-box .time {
  font-size: 10px;
  line-height: 12px;
  color: #a4a4a4;
  margin: 3px 0 0;
}

.message-box-sent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-box-sent .content {
  background: #cbeeff;
  border-radius: 13px 13px 0px 13px;
  margin-left: auto;
}

.new-user-created {
  margin: auto;
  background-color: #d6a9a9 !important;
  border-radius: 30px !important;
  padding: 4px 14px !important;
}

.message-box-sent .time {
  text-align: right;
}

.msg-date {
  text-align: center;
  margin: 15px 0;
}

.msg-date span {
  background: #36bdff;
  border-radius: 13px;
  color: #fff;
  display: inline-block;
  padding: 5px 14px;
  font-weight: 500;
  font-size: 12px;
}

.message-box .chat-thumb {
  border-radius: 13px 13px 13px 0px;
}

.message-control {
  background: #eaeaea;
  border-radius: 10px;
  overflow: hidden;
}

.message-control .form-control {
  background: transparent;
}

.message-wrapper {
  height: 100%;
}

.message-wrapper .modal-header {
  border-bottom: 2px solid #eaeaea;
}

.message-wrapper .modal-footer {
  border-top: 2px solid #eaeaea;
  border-top: 2px solid #eaeaea;
  z-index: 99999999;
  position: relative;
  background: white;
}

.modal-body::-webkit-scrollbar {
  width: 10px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.file-upload {
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: absolute;
  bottom: 100px;
  left: 0px;
  right: 0px;
  z-index: 9;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  width: 95%;
  margin: 0px auto;
}

.file-upload-visible {
  padding: 24px;
  max-height: 100%;
  z-index: 100;
}

.file-upload .btn {
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.file-upload .btn,
.file-upload .btn:hover,
.file-upload .btn:focus,
.file-upload .btn:not(:disabled):not(.disabled):active {
  background: linear-gradient(147.24deg, #3662ff -3.09%, #36bdff 101.18%);
}

.file-upload .btn img {
  filter: brightness(0) invert(1);
}

.file-upload-backdrop {
  background: rgba(41, 41, 41, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.uploadDoc {
  background: #eaeaea;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
  padding: 12px 20px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.uploadDoc input {
  position: absolute;
  height: 0px;
  width: 0px;
  opacity: 0;
}

.uploadDoc span {
  display: block;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3a3a3a;
}

.amount-label {
  background: #eaeaea;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #5b5b5b;
  max-width: 87%;
  margin: 15px auto 5px;
  text-align: center;
  min-height: 45px;
  font-weight: 700;
}

.post-modal .modal-dialog {
  border-radius: 30px;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
}

.chat-close-control {
  display: none;
}

.chat-search {
  margin-bottom: 20px !important;
  border-radius: 30px;
}

.chat-search .form-control {
  padding-left: 0;
}

.share-modal-content {
  max-width: 333px;
  text-align: center;
  border-radius: 30px;
  overflow: hidden;
  margin: 0 auto;
}

.share-modal-content .list-group-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #111111;
  padding: 20px;
  cursor: pointer;
  border-color: #e2e2e2;
}

.share-modal-content .list-group-item:hover {
  background: #f1f1f1;
}

.share-modal-content .list-report {
  color: #d80d0d;
}

.custom-modal .modal-content {
  border-radius: 30px;
}

.custom-modal .modal-header {
  padding: 20px;
}

.custom-modal .modal-body,
.custom-modal .modal-footer {
  padding: 10px 20px;
}

.custom-modal .btn-close {
  opacity: 0.8;
}

.post-user-control .text-time {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #818181;
}

.post-user-control .text-msg.user-comment {
  color: #323232;
  white-space: unset;
  font-weight: 400;
}

.comment-control .btn {
  font-size: 12px;
  margin: 10px 15px 0 0;
  padding: 0;
  text-decoration: none;
  color: #818181;
}

.comment-modal .modal-footer .form-control.border-0 {
  padding: 0;
}

.comment-modal .modal-footer .form-control.border-0::placeholder {
  font-weight: 500;
  color: #7d7d7d;
}

.comment-control .btn.btn-link-danger {
  color: #ff4949;
  font-weight: 500;
}

.post-user-control .post-user-logo.post-user-logo-sm {
  width: 26px;
  height: 26px;
  min-width: unset;
}

.comment-modal .cmt-img {
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-modal .cmt-img-wrapper {
  padding: 20px;
  background: #f5f5f5;
}

.custom-modal .modal-content-lg {
  border-radius: 30px;
  max-width: 1069px;
  width: 97%;
}

.post-user-control .post-user-logo-lg {
  width: 90px;
  height: 90px;
}

.post-user-control .post-user-logo-lg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.donate-modal .modal-header {
  border-bottom: 1px solid #e2e2e2;
}

.donate-modal .tip-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #3b3b3b;
  margin: 10px 0 0;
}

.donate-modal .username {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0296dd;
  display: block;
  margin: 15px 0 20px;
}

.donate-modal .amount {
  font-weight: 700;
  font-size: 40px;
  line-height: 68px;
  text-align: center;
  color: #0296dd;
}

.donate-modal .amount-label {
  border: 0 !important;
  margin: 10px 0 25px !important;
  height: 58px;
  width: 100%;
  outline: none !important;
}

.radio-price-group {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  padding: 0 20px;
}

.radio-price-group .btn {
  border: 1px solid #9d9d9d;
  background: transparent;
  border-radius: 32px;
  margin: 3px 6px;
  padding: 7px 22px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #9d9d9d;
}

.radio-price-group input:checked + .btn {
  background: #0296dd;
  border-color: #0296dd;
  color: #fff;
}

.file-label {
  background: #e2e2e2;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 230px;
  margin-bottom: 30px;
}

.file-label .span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  background: #fff;
  box-shadow: 0px 0px 40px rgba(140, 140, 140, 0.15);
  border-radius: 10px;
  padding: 10px 32px;
  cursor: pointer;
}

.file-box .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.caption-control {
  padding: 20px;
  border: 2px solid #e2e2e2;
  border-radius: 30px;
}

.caption-control textarea {
  resize: none;
}

.radio-price-group-circle {
  align-items: center;
  padding: 20px 0;
}

.radio-price-group-circle .btn {
  position: relative;
  padding: 8px 30px 8px 10px;
  background: #c0c0c0;
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  margin: 4px;
  white-space: nowrap;
  border: 0;
  color: #fff;
}

.radio-price-group-circle .btn::after {
  content: "";
  position: absolute;
  top: 12px;
  width: 16px;
  height: 16px;
  right: 10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.radio-price-group-circle input:checked + .btn::before {
  content: "";
  position: absolute;
  top: 15px;
  width: 10px;
  height: 10px;
  right: 13px;
  border-radius: 50%;
  background: #ffffff;
}

.caption-label {
  padding: 12px 4px;
  border-bottom: 1px solid #e2e2e2;
  font-size: 14px;
  line-height: 17px;
  color: #111111;
  display: flex;
  align-items: center;
}

.caption-label b {
  color: #3f3f3f;
  white-space: nowrap;
  font-weight: 500;
}

.caption-label i {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0296dd;
  margin: 0 5px 0 15px;
  font-style: normal;
}

.caption-label .form-control {
  border: 0;
  padding: 0 10px;
}

.photo-modal .modal-body,
.photo-modal .modal-footer {
  padding: 20px 20px 20px 20px;
}

.file-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.file-box .btn-group {
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.file-box .btn-group-delete {
  position: absolute;
  right: 25px;
  bottom: 25px;
  top: 0;
}

.file-box .btn-group .btn {
  background-color: #fff;
  height: 40px;
  width: 40px;
  margin-left: 20px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumb-btn {
  margin: 0 5px 0 15px;
  height: auto;
}

.thumb-btn img {
  filter: brightness(0) invert(1);
  width: 14px;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.file-label.file-label-sm {
  min-height: unset;
  height: auto;
  padding: 0px 20px 30px;
}

.audio-thumbnail {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #2b2f35;
  box-shadow: inset -8px -8px 20px rgba(255, 255, 255, 0.0529392),
    inset 8px 8px 20px rgba(0, 0, 0, 0.251038);
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid #1e1e1e;
}

.media-control {
  display: flex;
  align-items: center;
}

.audio-thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.audio-play img {
  margin-right: 15px;
  width: 50px;
}

.media-control .duration {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #717171;
  margin-bottom: 8px !important;
  margin-top: 30px !important;
}

.media-control .progress {
  background: #bababa;
  height: 9px;
  border-radius: 20px;
  overflow: visible;
  margin-bottom: 15px;
}

.media-control .progress-bar {
  overflow: visible;
  background: #3693ff;
  border-radius: 20px;
  position: relative;
}

.media-control .progress-bar::after {
  content: "";
  position: absolute;
  right: -10px;
  top: -6px;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.btn-link-primary {
  text-decoration: underline;
  padding: 0;
  color: #0296dd;
  font-weight: 500;
  font-size: 13px;
  box-shadow: 0 0 0 !important;
}

.post-text-areacontrol {
  font-weight: 500;
  font-size: 14px;
  color: #111111;
  padding: 25px !important;
  background: #e2e2e2;
  border-radius: 30px;
}

.text-post-modal .modal-content-sm {
  width: 464px;
}

.text-post-modal .modal-dialog.modal-dialog-centered {
  max-width: 464px !important;
  width: 100%;
}

.modal {
  backdrop-filter: blur(3.5px);
}

.post-tabs {
  margin: -22px -30px 25px;
}

.post-tabs .nav-item .nav-link {
  font-weight: 600;
  font-size: 16px;
  color: #aeaeae;
  padding: 14px;
  border-bottom: 2px solid #e2e2e2;
  border-radius: 0;
}

.nav-pills .nav-link.active {
  color: #676767;
  border-color: #676767;
  background: transparent;
}

.tab-follow-list .user-follow-item {
  margin: 20px 0;
}

.tab-follow-list .user-follow-name div.follow-logo,
.tab-follow-list .user-follow-name div.follow-logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: cover;
}

.tab-follow-list .user-follow-name p {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 24px;
  color: #111111;
}

.tab-follow-list .follow-button .btn-follow {
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 14px;
  color: #0296dd;
  border: 1px solid;
  padding: 6px 18px;
  background: transparent;
}

.tab-follow-list .unfollow-button .btn-unfollow {
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 14px;
  color: grey;
  border: 1px solid;
  padding: 6px 18px;
  background: transparent;
}

.hashtaglist a {
  display: block;
  padding: 5px 0;
  margin: 15px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hashtaglist .icon {
  width: 50px;
  height: 50px;
  border: 1px solid #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.hashtaglist h3.hashtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  margin-bottom: 0;
}

.hashtaglist p.counts {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #a2a2a2;
  margin: 0;
}

.story-modal .modal-dialog {
  max-width: 336px;
}

.story-modal .modal-dialog-scrollable .modal-content {
  overflow: visible;
}

.story-modal .story-img {
  width: 100%;
  object-fit: cover;
  border-radius: 0;
}

.story-modal .modal-body {
  height: 593px;
  overflow: hidden;
  border-radius: 0 0 30px 30px;
}

.story-modal .modal-header .dropdown .btn {
  transform: rotate(90deg);
  width: 50px;
  margin-right: -18px;
}

.story-modal .swiper {
  position: relative;
  height: 100%;
}

.story-modal .swiper-pagination {
  bottom: auto;
  top: 0;
  display: flex;
}

.story-modal .swiper-pagination-bullet {
  background-color: #fff;
  margin: 6px !important;
  flex: 1;
  width: auto;
  border-radius: 10px;
  opacity: 0.5;
  height: 4px;
}

.story-modal .swiper-pagination-bullet-active {
  opacity: 1;
}

.story-modal .modal-header .dropdown-menu.show {
  display: block;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 15%);
  border-radius: 8px;
  padding: 0;
  border: 0;
}

.story-modal .modal-header .dropdown-menu.show .dropdown-item {
  padding: 12px 8px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3b3b3b;
  border-bottom: 1px solid #efefef;
}

.story-modal .modal-header .dropdown-menu.show li:last-child .dropdown-item {
  border-bottom: 0px solid #efefef;
  color: #ef1f1f;
}

.story-modal .swiper-button-next,
.story-modal .swiper-button-prev {
  width: 29px;
  height: 29px;
  background: #ffffff;
  color: #000;
  border-radius: 50%;
}

.story-modal .swiper-button-next:after,
.story-modal .swiper-button-prev:after {
  font-size: 18px;
}

.story-modal .swiper-button-next {
  right: -40px;
}

.story-modal .swiper-button-prev {
  left: -40px;
}

.free-label {
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.free-label .form-check-input {
  display: none;
}

.free-label label {
  padding: 4px 8px 4px 4px;
  background: #d9d9d9;
  border-radius: 50px;
  cursor: pointer;
  margin-left: auto;
}

.free-label .on,
.free-label .off {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000000;
  display: flex;
  align-items: center;
}

.free-label .off {
  display: none;
}

.free-label .on::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: #0296dd;
  margin-right: 5px;
}

.free-label.form-switch .form-check-input:checked + label {
  background-color: #0296dd;
  padding: 4px 4px 4px 8px;
}

.free-label.form-switch .form-check-input:checked + label .off {
  display: flex;
  color: #fff;
}

.free-label.form-switch .form-check-input:checked + label .on {
  display: none;
}

.free-label.form-switch .form-check-input:checked + label .off::after {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: #fff;
  margin-left: 5px;
}

.golive-modal .live-btn {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  cursor: pointer;
}

.golive-modal .live-btn span {
  display: block;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background: #f20c0c;
  border: 3px solid #fff;
  box-shadow: 0 0 0px 5px #000000;
  margin-bottom: 15px;
}

.golive-modal .scroll-box {
  overflow: auto;
  max-height: 450px;
  margin-bottom: 20px;
}

.golive-modal .scroll-box::-webkit-scrollbar {
  width: 10px;
}

.golive-modal .scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.golive-modal .scroll-box::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.exit-live-stream {
  position: absolute;
  right: 20px;
  top: -18px;
  border: 0;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 13px;
  background: #828282;
  border-radius: 30px;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 99;
}

.golive-modal .modal-body {
  padding-top: 50px;
}

.golive-modal .amount-label {
  background: #f0f0f0;
  margin-bottom: 0px !important;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
}

.golive-modal .cmt-img {
  max-height: 600px;
}

.paid-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.stream-comment {
  padding: 8px 20px 20px;
  background: #eaeaea;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: calc(100% - 40px);
}

.stream-comment .stream-body {
  flex: 1;
  overflow: auto;
}

.stream-comment .stream-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
  border-bottom: 1px solid #dadada;
  padding: 10px 10px 15px;
  margin: 0 -20px;
}

.stream-comment .stream-footer {
  border: 1px solid #c2c2c2;
  border-radius: 40px;
  width: 100%;
  margin: 15px auto 0;
}

.stream-comment .stream-footer .post-user-control {
  padding: 8px 8px !important;
}

.stream-comment .stream-footer .btn {
  margin-right: -10px;
}

.golive-modal .view-btn {
  background: linear-gradient(92.63deg, #3662ff 0.71%, #36bdff 100.85%);
  border-radius: 15px;
  padding: 15px;
  color: #fff;
  text-align: center;
  width: calc(100% - 40px);
  margin: 60px auto 0px;
}

.golive-modal .stream-comment .post-user-control {
  padding: 14px 13px 0;
}

.golive-modal .stream-comment .post-user-control .post-user-name {
  line-height: 20px;
}

.golive-modal .stream-comment .post-user-control .text-msg {
  font-size: 12px;
  line-height: 15px;
}

.live-search {
  display: flex;
  background: #d3d3d3;
  border-radius: 8px;
  padding: 8px;
  margin: 15px 0 0;
}

.live-search input,
.stream-comment .stream-footer input {
  padding: 0;
  border: 0;
  background: transparent;
  outline: none !important;
}

.golive-modal.modal-dialog-scrollable .modal-content {
  overflow: hidden;
}

.subscribe-box .sub-amount {
  font-weight: 600;
  font-size: 36px;
  text-align: right;
  color: #0296dd;
  margin: 0px;
}

.subscribe-box .dayslabel {
  background: #a7a7a7;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
}

.subscribe-box .date-label {
  font-weight: 500;
  font-size: 14px;
  color: #111111;
  margin: 5px 0;
}

.subscribe-box {
  border: 1px solid #0296dd;
  border-radius: 13px;
  padding: 10px 20px 20px;
  position: relative;
  margin-bottom: 20px;
}

.subscribe-box:last-child {
  margin-bottom: 0;
}

.subscribe-box .post-user-control {
  padding: 20px 0 10px;
}

.subscribe-box .post-user-control .post-user-logo.post-user-logo-sm {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.subscribe-box .post-user-control .post-user-logo.post-user-logo-sm .img-fluid,
.subscribe-box .sb-avatar {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.subscribe-box .post-user-control .post-user-name {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 6px;
}

.subscribe-box .plan-label {
  background: #0296dd;
  border-radius: 0 0px 14px 14px;
  padding: 6px 9px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 0;
}

.past-subscribe-box {
  border: 1px solid #b7b7b7;
}

.subscribe-box.past-subscribe-box .plan-label {
  background: #b7b7b7;
}

.subscribe-box.past-subscribe-box .date-label,
.subscribe-box.past-subscribe-box .sub-amount {
  color: #b7b7b7;
}

.wallet-box {
  padding: 20px 30px;
  background: linear-gradient(93.69deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-box .balance-labels {
  font-weight: 500;
  font-size: 16px;
  color: #f0f0f0;
  margin: 0;
}

.wallet-box .wallet-amount {
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  margin: 0;
}

.gray-label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #838383;
  margin: 30px 0;
}

.coin-slider .swiper-slide {
  width: 131px;
}

.coin-box {
  border: 1px solid #0296dd;
  border-radius: 13px;
  padding: 15px 15px 0;
  text-align: center;
}

.coin-box .dollar-sign {
  width: 52px;
}

.coin-box p {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin: 0 0 15px;
}

.coin-box .price {
  padding: 9px;
  color: #fff;
  background: #0296dd;
  border-radius: 0px 0px 11px 11px;
  margin: 0 -15px;
  text-align: center;
}

.coin-slider {
  overflow: hidden;
  width: calc(100% + 30px);
}

.history-box {
  padding: 30px;
  background: #f0f0f0;
  border-radius: 13px;
}

.history-box .history-content {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.history-box .history-content:last-child {
  margin-bottom: 0;
}

.history-content .date-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 11px 13px;
  background: #ececec;
  border-radius: 10px;
  font-weight: 700;
  font-size: 26px;
  color: #111111;
  width: 66px;
  line-height: 22px;
  margin-right: 15px;
}

.history-content .date-icon span {
  font-weight: 400;
  font-size: 16px;
  color: #111111;
}

.history-content .name {
  font-weight: 700;
  font-size: 20px;
  color: #111111;
  margin: 0;
}

.history-content p {
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
  margin: 2px 0;
}

.history-content .price {
  font-weight: 600;
  font-size: 24px;
  color: #059d14;
  margin: 0 10px 0 0;
}

.history-content .price-danger {
  color: #c30a0a;
}

.history-box .transaction-btn {
  font-weight: 600;
  font-size: 16px;
  color: #0296dd;
  padding: 15px 10px;
  text-align: center;
  display: block;
  border-top: 1px solid #b5b5b5;
  width: calc(100% + 60px);
  margin: 0 -30px -30px;
  border-radius: 0;
}

.plan-box {
  padding: 25px;
  background: #f0f0f0;
  border-radius: 13px;
  margin-bottom: 25px;
}

.plan-box .plane-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
}

div.switcher label input {
  display: none;
}

div.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 50px;
  height: 12px;
  background: #ffffff;
  box-shadow: inset 0px 0px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

div.switcher label input + span small {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  width: 26px;
  height: 26px;
  background: #ffffff;
  border: 2px solid #0296dd;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  top: -7px;
  left: -4px;
}

div.switcher label input:checked + span {
  background: #0296dd;
  box-shadow: inset 0px 0px 1.5px rgba(0, 0, 0, 0.5);
}

div.switcher label input:checked + span small {
  left: 50%;
  background: #0296dd;
  border-color: #fff;
}

.price-group {
  width: 135px;
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 7px 10px;
  border-radius: 10px;
}

.price-group .input-group-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #767676;
  padding: 0;
  width: 20px;
  background: transparent;
  border: 0;
}

.price-group .form-control {
  padding: 0 !important;
  border: 0;
}

.inputlabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #111111;
  margin: 20px 0 10px;
}

.active-plan-box {
  background: linear-gradient(94.48deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
}

.active-plan-box .plane-title,
.active-plan-box .inputlabel {
  color: #fff;
}

.active-plan-box div.switcher label input + span small {
  background: #0296dd;
  border: 2px solid #fff;
}

.package-btn {
  background: linear-gradient(91.59deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  padding: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border: 0;
  width: 100%;
}

.package-btn img {
  filter: brightness(0) invert(1);
}

.package-wrapper {
  border-top: 1px solid #e2e2e2;
  margin-top: 30px;
  padding-top: 30px;
}

.package-plan {
  background: #7739ff;
  border-radius: 12px;
  padding: 28px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.package-plan:last-child {
  margin-bottom: 0;
}

.package-plan .pc-label {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.package-plan .p-title {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}

.package-plan .share-btn {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  height: 55px;
}

.package-plan-orange {
  background: #ff7246;
}

.package-plan-pink {
  background: #ff4689;
}

.package-plan-green {
  background: #14bc8a;
}

.package-modal .modal-title {
  font-weight: 600;
  font-size: 22px;
  color: #111111;
  text-align: center;
}

.active-list {
  background: #e7f7ff;
}

.search-package-list {
  margin: 0 -16px;
  position: relative;
  padding-bottom: 60px;
}

.search-package-list .post-user-control {
  padding: 9px 13px;
}

.package-save-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 78.65%
  );
  border-radius: 0px 0px 30px 30px;
  padding: 10px 0 30px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.package-save-btn .btn-login {
  width: 135px;
  margin: 0 auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.package-modal .modal-dialog-scrollable .modal-content {
  overflow: hidden;
}

.package-modal .modal-dialog-scrollable .modal-body {
  max-height: 530px;
}

.package-form {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 25px;
  text-align: center;
}

.package-form label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #111111;
  margin-bottom: 20px;
}

.package-form .form-control {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  max-width: 392px;
  margin: 0 auto 30px;
}

.package-form .btn {
  width: 160px;
  margin: 0 auto;
}

.select-media {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 20px;
  display: block;
  margin: 20px 0;
}

.select-media .border {
  border: 1px dashed #838383 !important;
  border-radius: 8px;
  padding: 33px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #111111;
}

.package-img {
  max-width: 100%;
  margin: 20px auto;
  border-radius: 18px;
}

.audio-box {
  padding: 90px 20px;
  background: #f5f5f5;
  border-radius: 18px;
}

.audio-thumbnail.audio-thumbnail-relative {
  position: relative;
  top: auto;
  left: auto;
  transform: unset;
  width: 258px;
  margin: 0 auto;
}

.subscribe-box.trial-plan {
  border: 1px solid #3662ff;
}

.subscribe-box.trial-plan .plan-label {
  background: #3662ff;
}

.subscribe-box.trial-plan .sub-amount {
  color: #3662ff;
}

.subscribe-box .btn.text-danger {
  color: #f20c0c !important;
  font-size: 12px;
}

.subscribe-box .btn.text-info {
  font-size: 12px;
  color: #0296dd;
}

.subscribe-box.trial-plan {
  padding-bottom: 50px;
}

.subscribe-box .trial-text {
  background: #3662ff;
  border-radius: 10px 10px 0px 0;
  color: #fff !important;
  padding: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 304px;
  height: 23px;
  margin: 0 auto;
  /* max-height: 23px; */
  font-weight: 500;
  font-size: 14px;
}

.donate-btn {
  padding: 11px 40px !important;
  height: auto;
}

.test {
  height: 100%;
  width: 100%;
}

.post-user-control .post-user-logo img {
  border-radius: 50%;
  object-fit: cover;
}

.select-user {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.select-user h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.select-user p {
  font-size: 14px;
}

.setting-container .setting-grid-box.messages-container {
  position: relative;
}

.settings-tab {
  position: inherit;
  top: 0;
}

.settings-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  width: 100%;
}

.hide-input input {
  position: absolute;
  height: 0px;
  width: 0px;
  opacity: 0;
}

.uploadDoc img {
  height: 140px;
}

.post-modal {
  position: absolute;
  bottom: 90px;
  left: 0px;
  right: 0px;
  max-width: 95%;
  width: 100%;
  margin: 0px auto;
  max-height: 0px;
  transition: all ease 0.5s;
  padding: 24px;
  background-color: white;
  border-radius: 5px;
  z-index: 999;
  opacity: 0;
  padding: 0 !important;
}

.post-modal.show-post-modal {
  max-height: 100%;
  opacity: 1;
  padding: 0px 24px 24px 24px !important;
}

.post-modal.show-post-modal-paidMedia {
  max-height: 100%;
  opacity: 1;
}

.post-modal.coin-post-modal {
  max-height: 100%;
  opacity: 1;
  border-radius: 10px;
  width: 380px;
}

.post-modal .btn-login,
.post-modal .btn-login:hover,
.post-modal .btn-login:focus {
  background: linear-gradient(
    95.14deg,
    #3662ff 2.94%,
    #36bdff 97.82%
  ) !important;
  padding: 8px 20px;
  color: white !important;
  border-radius: 5px;
}

.coin-input input {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
  background: #eaeaea;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #eaeaea;
  text-align: center;
  margin: 10px auto;
}

.coin-input input::placeholder {
  background: #eaeaea;
}

.item-value p {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
}

.item-value {
  justify-content: center;
  gap: 10px;
}

.coin-amount {
  width: 100%;
  max-width: 250px;
  margin: 0px auto;
}

/************ package style************/
.paid-package-section .heading-text h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 35px;
  color: #000000;
}

.paid-package-section .add-new-package {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  cursor: pointer;
  background: linear-gradient(91.59deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  padding: 32px 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.paid-package-section .package-box h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 110.5%;
  color: #ffffff;
  margin-bottom: 4px;
}

.paid-package-section .package-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 110.5%;
  color: #ffffff;
  margin: 0;
}

.paid-package-section .package-box .lest-section {
  width: calc(100% - 50px);
  padding: 28px 0px;
}

.paid-package-section .package-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background: #7739ff;
  border-radius: 12px;
  margin-bottom: 30px;
  cursor: pointer;
}

.paid-package-section .package-box:nth-child(4n) {
  background: #7739ff;
}

.paid-package-section .package-box:nth-child(2n) {
  background: red;
}

.paid-package-section .package-box .right-section {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.paid-package-section .package-listing {
  margin-top: 80px;
}

.create-package h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 35px;
}

.create-package-box {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.create-package-box label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  margin-bottom: 20px;
  color: #111111;
}

.create-package-box > div {
  text-align: center;
  width: 100%;
  margin-bottom: 30px !important;
  max-width: 392px;
}

.create-package input {
  height: 34px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

.create-package-box button {
  margin-top: 10px;
  width: 160px;
  height: 40px;
  background: #0296dd;
  border-radius: 8px;
}

.select-media .upload-img {
  max-width: 529px;
  width: 100%;
  height: 129px;
  background: #f0f0f0;
  border-radius: 13px;
}

.select-media input {
  display: none;
}

.select-media label {
  padding: 20px;
  height: 89px;
  border: 1px dashed #838383;
  border-radius: 8px;
  width: 100%;
  margin: 0;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #111111;
  justify-content: center;
  align-items: center;
}

.package-detail .heading-text h6,
.create-package .heading-text h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 35px;
  color: #000000;
}

.package-detail .heading-text img,
.create-package .heading-text img {
  position: absolute;
  left: 0;
  top: "8px";
  cursor: pointer;
}

.package-detail .heading-text,
.create-package .heading-text {
  position: relative;
}

.preview-img img {
  height: 100%;
  width: 100%;
}

.preview-img {
  width: 426px;
  height: 426px;
  background: #f5f5f5;
  border-radius: 18px;
  margin: 30px 0px;
  object-fit: cover;
}

.preview-list {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

/* cicle */
.circle {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: green;
  border-radius: 50%;
}

.text {
  margin: auto;
}

.tag-item {
  background-color: hsl(0, 0%, 90%);
  display: inline-block;
  font-size: 14px;
  border-radius: 3px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: inherit;
  width: 22px;
  height: 22px;
  /* border-radius: 50%; */
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/********** share popup ***********/
.share-popup .user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px;
}

.share-popup .user-details .left-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.share-popup .user-details .left-section img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.share-popup .user-details .left-section h6 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #111111;
  text-transform: capitalize;
}

.share-popup .user-listing {
  display: grid;
  height: 500px;
  overflow: auto;
}

.share-popup .share-popup-content .user-listing {
  display: inherit;
  height: 500px;
  margin-bottom: 45px;
}
.main-select-all {
  display: flex;
  justify-content: flex-end;
}
.share-popup .modal-body {
  padding: 27px 0px;
}

.share-popup .modal-header {
  padding: 21px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #e0e0e0;
}

.share-popup .modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 110.5%;
  color: #111111;
}

.share-popup button.btn-close {
  position: absolute;
  right: 35px;
}

.share-popup .user-listing::-webkit-scrollbar {
  width: 0px;
}

.share-popup .user-listing::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.share-popup .user-listing::-webkit-scrollbar-thumb {
  background: #888;
}

.share-popup .modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 510px;
  width: 100%;
}

.share-popup .modal-content {
  background: #ffffff;
  border-radius: 30px;
}

.share-popup .right-section input {
  height: 14px;
  border: 1.5px solid #000000;
  border-radius: 3px;
  width: 14px;
}

.share-popup .search-input input {
  width: 100%;
  height: 38px;
  background: #f0f0f0;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  padding: 5px 20px 5px 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 110.5%;
  color: #797979;
}

.share-popup .search-input {
  margin: 0px 35px;
}

.share-popup .select-all {
  padding: 10px 35px 8px;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #0296dd;
}

.send-btn {
  width: 100%;
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 78.65%
  );
  border-radius: 0px 0px 30px 30px;
}

.send-btn button {
  width: 135px;
  height: 39px;
  background: #0296dd;
  border-radius: 8px;
  border: 1px solid #0296dd;
  margin-bottom: 5px;
}

.share-popup .search-input img {
  position: absolute;
  left: 20px;
  top: 13px;
}

.share-popup .search-input {
  position: relative;
}

/****** ,edia popup ************/
.upload-media-popup .modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 595px;
  width: 100%;
  height: 787px;
}

.upload-media-popup .modal-content {
  background: #ffffff;
  border-radius: 30px;
  padding: 0px;
}

.choose-option-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.choose-option-list .choose-box {
  width: 69px;
  height: 61px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  cursor: pointer;
}

.choose-option-list .choose-box.active {
  background: #0296dd;
}

.choose-option-list p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 0;
}

.upload-media-box {
  height: 479px;
  border: 1px dashed #9d9d9d;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
  cursor: pointer;
}

.upload-media-box p {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.upload-media-popup .modal-header {
  border: none;
  padding: 10px 0px;
}

.upload-media-box input {
  display: none;
}

.upload-media-popup .add-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
}

.upload-media-popup .add-btn button {
  max-width: 194px;
  height: 54px;
  background: #0296dd;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #0296dd;
}

.upload-media-popup .modal-header {
  padding: 25px 25px 6px 25px;
}

.upload-media-popup .modal-body {
  padding: 5px 58px 30px;
}

.upload-media-box .previewImg {
  border-radius: 5px;
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.preview-section img {
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
  width: 100%;
}

.preview-section video {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.upload-media-popup .add-more {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  cursor: pointer;
}

.preview-section {
  position: relative;
  border: none;
  background: #f5f5f5;
  border-radius: 10px;
}

.upload-media-popup .add-more img {
  height: 100%;
  width: 20px;
  margin: 0;
  max-width: 100%;
  border-radius: initial;
  object-fit: contain;
}

.thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 38px;
}

.thumbnail p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3f3f3f;
  margin: 0;
}

.thumbnail .upload-thumb {
  width: 41px;
  height: 22px;
  background: #36bdff;
  border-radius: 3px;
  margin: 0px 10px 0px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-thumb img {
  filter: invert(1);
  height: 12px;
}

.thumb-btn h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9f9f9f;
  margin: 0;
}

.thumbnail input,
.thumbnail input:focus {
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 24px;
  color: #9f9f9f;
  box-shadow: none;
  outline: none;
}

.audio-title {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 14px;
  margin-bottom: 23px;
}

.choose-option-list .choose-box.active img {
  filter: invert(1);
}

.video-media-box {
  height: 404px;
}

.audio-media-box {
  height: 339px;
}

.add-more input {
  display: none;
}

.add-more label {
  margin: 0;
  cursor: pointer;
}

.preview-data-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 25px;
}

.preview-data-list .preview-data {
  width: 100%;
  filter: drop-shadow(0px 32px 90px rgba(59, 59, 59, 0.05));
  border-radius: 18px;
  max-width: 426px;
  height: 250px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.preview-data-list i.las.la-trash {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 42px;
  width: 42px;
  background: #3c3939;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.package-detail .heading-text .action-btns {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  gap: 10px;
}

.package-detail .heading-text .action-btns i {
  font-size: 28px;
  cursor: pointer;
}

.package-detail .heading-text .action-btns i.las.la-trash {
  color: #d50d0d;
}

iframe {
  pointer-events: none;
}

/********* unlock popup ********/
.unlock-popup .winter-collection {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 20px;
  text-align: center;
  color: #0296dd;
}

.coins {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bfbcbc;
  max-width: fit-content;
  margin: 12px auto;
  padding: 5px 20px;
  border-radius: 30px;
}

.modal-footer.justify-content-center.border-0.pt-0 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unlock-msg {
  max-width: 175px;
  background: #0296dd52;
  text-align: center;
  height: 75px;
  border-radius: 5px 5px 5px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.unlock-msg p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.unlock-msg .view-text {
  height: calc(100% - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload .btn i {
  font-size: 40px;
  color: white;
}

.unlock-msg h6 {
  margin: 0;
  background: #0296dd;
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 14px;
  border-bottom-right-radius: 5px;
  line-height: initial;
}

.coins h6 {
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0296dd;
  font-size: 24px;
  font-weight: 600;
}

.unlock-popup .media-btns .btn p {
  margin: 0;
  line-height: initials;
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
}

.unlock-popup {
  padding: 20px 0px;
}

.unlock-popup h4 {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.unlock-popup .media-btns .btn label {
  margin: 0;
}

.unlock-popup .media-btns .btn {
  padding: 12px 8px;
  min-width: 80px;
  background: transparent !important;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content !important;
  width: fit-content !important;
  border-radius: 5px !important;
}

.unlock-popup .media-btns .btn img {
  filter: initial !important;
  height: 20px;
}

.unlock-popup .media-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
}

.unlock-popup .modal-footer button,
.unlock-popup .modal-footer button:hover,
.unlock-popup .modal-footer button:focus {
  height: 40px;
  padding: 5px 20px !important;
  width: fit-content;
  border: 1px solid #0296dd !important;
  margin: 30px 0px 0px;
  background: #0296dd !important;
  padding: 5px 30px !important;
  font-weight: 600;
  border-radius: 12px;
}

.coins h6 span {
  font-size: 16px;
  margin-left: 5px;
}

.unlock-popup h4 {
  padding-bottom: 0px;
  border-bottom: 1px solid #dddddd;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 10px;
}

/****** new unlock css *********/
.unlock-message-details .user-detai-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border: 1px solid #d8d8d8;
  border-left: 0px;
  border-right: 0px;
}

.unlock-message-details .user-detai-section .left-section {
  display: flex;
  align-items: center;
}

.unlock-message-details .user-detai-section .left-section .user-name {
  margin-left: 12px;
}

.unlock-message-details .user-detai-section .left-section img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.unlock-message-details .user-detai-section .left-section .user-name h6 {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}

.unlock-message-details .user-detai-section .left-section .user-name p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.unlock-message-details .nav-tabs {
  border: none;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unlock-message-details .nav-tabs li .active {
  background: #0296dd;
  border-radius: 30px;
  padding: 5px 20px;
  color: white;
}

.unlock-message-details .nav-tabs button {
  font-size: 13px;
  font-weight: 500;
  color: black;
  border-radius: 30px !important;
  padding: 5px 20px;
  border: 1px solid #dcd2d2;
  width: 100%;
}

.unlock-message-details .nav-tabs li {
  width: 20%;
}

.unlock-message-details .user-detai-section button {
  background: #efefef;
  border: 1px solid #efefef;
  font-size: 12px;
  border-radius: 5px;
  color: black;
  font-weight: 500;
}

.unlock-popup .modal-body {
  padding: 0;
}

.unlock-message-details {
  padding: 0px 20px;
}

.unlock-message-details {
  padding: 0px 20px 20px;
}

.unlock-popup .heading-text {
  text-align: center;
  position: relative;
  padding: 16px 0px;
}

.unlock-popup .heading-text img {
  position: absolute;
  left: 20px;
  height: 13px;
  top: 20px;
  cursor: pointer;
}

.unlock-popup .heading-text h6 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.video-section-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 400px;
  overflow: auto;
}

.video-section-list .video-box {
  width: calc(50% - 10px);
}

.video-section-list .video-box video source {
  height: 100%;
  width: 100% !important;
}

.video-section-list .video-box video {
  height: 200px;
  border-radius: 5px;
}

.video-section-list::-webkit-scrollbar {
  width: 5px;
}

.video-section-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.video-section-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.video-section-list .video-box img {
  height: 200px;
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
}

.video-section-list .video-box audio {
  width: 100%;
}

.audio-list .video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background: transparent;
}

.unlock-popup.modal {
  z-index: 99999999999;
}

.modal-backdrop.show {
  z-index: 999999999999;
}

.preview-data-list .preview-data img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.preview-data-list .preview-data video,
.preview-data-list .preview-data video source {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.modal {
  z-index: 99999999999;
}

.header .center-part-content .search-box img {
  left: 12px;
  position: absolute;
  top: 14px;
  height: 16px;
}

.header .user-details ul li {
  cursor: pointer;
}

.header .center-part-content .search-box input {
  padding-left: 36px;
  height: 42px;
  max-width: auto;
  width: 100%;
}

.header .center-part-content .search-box .dropdown.v {
  border-radius: 5px;
  border: 1px solid lightgrey;
}

.header .center-part-content .search-box .dropdown.v ul {
  padding: 0px;
}

.header .center-part-content .search-box .dropdown.v li {
  padding: 5px 15px;
}

.header .center-part-content .search-box .dropdown.v li .item_text1 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.header .center-part-content .search-box .dropdown.v li .item_text2 {
  font-size: 12px;
}

.chat-search input {
  font-size: 14px;
}

.chat-search img {
  height: 15px;
}

.preview-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.preview-popup button {
  border-radius: 5px !important;
  height: 40px !important;
}

.preview-popup img,
.preview-popup video {
  height: 250px;
  object-fit: cover;
  border-radius: 7px;
  margin: 0px auto;
}

.preview-popup {
  text-align: center;
}

.setting-grid-box.box-container.messages-container .settings-tab {
  position: relative !important;
}

.messages-container .file-upload label {
  margin: 0;
}

.messages-container .file-upload-section button,
.messages-container .file-upload-section img {
  cursor: pointer;
}

.message-box.message-box-sent {
  text-align: right;
}

.message-box.message-box-sent .unlock-msg {
  margin-left: auto;
}

.message-box.message-box-sent .unlock-msg h6 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px;
}

.modal {
  z-index: 999999999999 !important;
}

.loader {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loader .spinner-grow {
  height: 50px;
  width: 50px;
}

/*********** upload image popup **********/
.upload-image-popup .file-label {
  height: 100%;
  max-width: 555px;
  background: #e2e2e2;
  border-radius: 30px;
}

.upload-image-popup .left-section {
  max-width: 555px;
  width: 100%;
}

.upload-image-popup .left-section .upload-image-text {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(140, 140, 140, 0.15);
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  padding: 17px 32px;
  color: #000;
}

.upload-image-popup .left-section .upload-image-text label {
  margin-bottom: 0px;
}

.upload-image-popup .right-section {
  max-width: calc(100% - 575px);
  width: 100%;
}

.upload-image-popup .right-section button {
  width: 100%;
  height: 60px;
  background: #0296dd;
  border-radius: 12px;
  border: 1px solid #0296dd;
  font-weight: 600;
  font-size: 24px;
  line-height: 110.5%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.upload-image-popup .modal-dialog {
  max-width: 1039px;
  width: 100%;
}

.upload-image-popup .modal-content {
  border-radius: 30px;
  padding: 25px;
}

.upload-image-popup .modal-body {
  padding: 0;
}

.upload-image-popup .main-section.m-0 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.box-container .clip-button button {
  display: flex;
  align-items: center;
}

/****** wallet **********/
.transaction-history-section .transaction-list {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 14px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.transaction-history-section .left-section {
  display: flex;
  align-items: center;
}

.transaction-history-section .details h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 110.5%;
  /* or 22px */
  display: flex;
  align-items: center;
  color: #111111;
  margin-bottom: 4px;
}

.transaction-history-section .details p {
  font-weight: 500;
  font-size: 14px;
  line-height: 110.5%;
  /* identical to box height, or 18px */
  display: flex;
  align-items: center;
  color: #6b6b6b;
  margin: 0;
}

.transaction-history-section .date {
  color: #111111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 66px;
  background: #ececec;
  border-radius: 10px;
  margin-right: 12px;
}

.transaction-history-section .date h6 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 120.5%;
  font-family: "Montserrat";
}

.transaction-history-section .date p {
  margin: 0;
  font-weight: 500;
}

.transaction-history-section .right-section h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 110.5%;
  /* identical to box height, or 27px */
  display: flex;
  align-items: center;
  text-align: right;
  font-family: "Montserrat";
  margin: 0;
}

.transaction-history-section .right-section h6.added-amt {
  color: #059d14;
}

.transaction-history-section .right-section h6.debit-amt {
  color: #c30a0a;
}

.transaction-history-section .right-section h6.notadded-amt {
  color: #c30a0a;
}

.transaction-history-section p {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #838383;
}

.transaction-history-section .history-box {
  margin-top: 30px;
  padding: 0;
}

.transaction-history-section {
  margin-top: 40px;
}

.buy-coin-section {
  margin-top: 40px;
}

.buy-coin-section p {
  font-weight: 600;
  font-size: 16px;
  line-height: 110.5%;
  text-align: center;
  color: #838383;
}

.your-amount {
  background: linear-gradient(93.69deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}

.your-amount .left-section p {
  font-weight: 500;
  font-size: 16px;
  line-height: 110.5%;
  /* identical to box height, or 18px */
  display: flex;
  align-items: center;
  color: #f0f0f0;
  margin-bottom: 3px;
}

.your-amount .left-section h6 {
  font-weight: 600;
  font-size: 36px;
  line-height: 110.5%;
  /* identical to box height, or 40px */
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Montserrat";
  margin: 0;
}

.my-wallet-page .title h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

.view-more-history {
  border-top: 1px solid #b5b5b5;
  padding: 12px 15px;
}

.view-more-history h6 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 110.5%;
  /* identical to box height, or 18px */
  text-align: center;
  font-family: "Montserrat";
  color: #0296dd;
}

.transaction-history-section .top-section {
  padding: 30px 30px 10px;
}

.amount-box .head {
  background: #0296dd;
  padding: 10px;
  border-radius: 0px 0px 13px 13px;
  color: #ffffff !important;
}

.amount-box {
  width: 90%;
  border: 1px solid #0296dd;
  border-radius: 13px;
  text-align: center;
}

.amount-box img {
  height: 26px;
}

.amount-box .box-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.amount-box .box-content p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: #000000;
}

.amount-box img {
  height: 26px;
}

/* .slider {
  display: flex;
  gap: 26px;
} */

/******** subscriber page *************/
.subscriber-page .subscribe-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 110.5%;
  color: #111111;
}

.subscriber-page .subscribe-box .sub-amount {
  font-weight: 600;
  font-size: 36px;
  line-height: 110.5%;
  color: #b7b7b7;
  margin-bottom: 23px;
}

.subscriber-body-text {
  margin-bottom: 23px;
}

.subscriber-page .disabled-box p {
  color: #b7b7b7;
}

.subscriber-page .active-plan .sub-amount {
  font-weight: 600;
  font-size: 36px;
  line-height: 110.5%;
  text-align: right;
  color: #0296dd;
}

/********** my plans ***********/
.myplans-page .plans-supscription-box {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 20px 25px;
  margin-bottom: 40px;
}

.myplans-page .top-box h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
  font-family: "Montserrat";
  margin: 0;
}

.myplans-page .top-box {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myplans-page .form-group {
  margin: 0;
  position: relative;
}

.myplans-page .form-group label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #111111;
  margin-bottom: 8px;
}

.myplans-page .form-group input {
  width: 135px;
  height: 31px;
  left: 686px;
  top: 308px;
  background: #ffffff;
  border: 1px solid #dddddd;
  font-size: 14px;
  padding-left: 22px;
  border-radius: 8px;
}

.myplans-page .form-group span {
  position: absolute;
  top: 32px;
  left: 8px;
}

.myplans-page .plans-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myplans-page .plans-btn button {
  width: 160px;
  height: 40px;
  background: #0296dd;
  border: 1px solid #0296dd;
  border-radius: 8px;
}

.myplans-page .check-box input[type="checkbox"] {
  position: relative;
  appearance: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all ease 0.5s;
  width: 46px;
  height: 12px;
  background: #ffffff;
  box-shadow: inset 0px 0px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.myplans-page .check-box input:checked[type="checkbox"] {
  background: #0296dd;
  transition: all ease 0.5s;
}

.myplans-page .check-box input[type="checkbox"]::after {
  position: absolute;
  content: "";
  top: -8px;
  left: -15px;
  border-radius: 50%;
  transition: 0.4s;
  width: 26px;
  height: 26px;
  background: #ffffff;
  border: 2px solid #0296dd;
  transition: all ease 0.5s;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.myplans-page .check-box input:checked[type="checkbox"]::after {
  left: calc(100% - 15px);
  transition: all ease 0.5s;
}

/******* slider ********/
.center-box .story {
  width: fit-content;
  margin-right: 25px;
}

.swiper-slide {
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.swiper-slide .slick-slider {
  display: contents;
}

.swiper-slide .next-arrow {
  width: 22px;
  height: 22px;
  box-shadow: 0px 0px 11.6129px rgb(0 0 0 / 15%);
  position: absolute;
  right: 40px;
  z-index: 9999;
  background: #ffffff !important;
  border-radius: 50%;
  top: 22px;
  cursor: pointer;
}

.box-container.suggestion-container::-webkit-scrollbar {
  width: 0px;
}

.box-container.suggestion-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.box-container.suggestion-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.box-container.suggestion-container {
  height: 260px;
  overflow: auto !important;
}

/****** share popup **********/
.post-list-box .post-image img {
  object-fit: cover;
  height: auto;
  width: 100%;
}

.action-modal-popup .modal-dialog {
  width: 333px;
}

.action-modal-popup .modal-dialog .modal-content {
  height: auto;
  background: #ffffff;
  border-radius: 30px;
}

.action-modal-popup .modal-body {
  padding: 0;
  border-radius: 30px;
}

.action-modal-popup .list-group .list-group-item {
  border: 1px solid #e2e2e2;
  padding: 20px 0px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  color: #111111;
  cursor: pointer;
}

.action-modal-popup .list-group-item:first-child {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.action-modal-popup .list-group-item:last-child {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.loading-hover-effect {
  height: 160px;
  justify-content: center;
  align-items: center;
}
.action-modal-popup .list-group-item.list-report {
  color: #d80d0d;
}

.list-group-item.inline {
  position: relative;
  display: flex;
  padding: 4.5rem 0rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.action-modal-popup .list-group-item .share-btn {
  font-weight: 400;
  font-size: 12px;
  padding-left: 38px;
  line-height: 20px;
  text-align: center;
  color: #111111;
  cursor: pointer;
  border-color: #e2e2e2;
  background-color: transparent;
  border: none;
  outline: none;
}

/********* comment popup *********/
.custom-modal.comment-modal .modal-dialog {
  max-width: 1069px;
  width: 100%;
}

.custom-modal.comment-modal .cmt-img-wrapper {
  height: 610px;
  max-width: 612px;
  padding: 25px;
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.custom-modal.comment-modal .cmt-img-wrapper .img-fluid,
.custom-modal.comment-modal .cmt-img-wrapper video {
  border-radius: 30px;
  background: white;
}

.custom-modal.comment-modal .right-section {
  width: calc(100% - 612px);
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.custom-modal.comment-modal .main-comment-box {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.custom-modal.comment-modal .modal-body {
  padding: 0;
  height: 612px;
}

.custom-modal.comment-modal .right-section .modal-body {
  height: calc(100% - 175px);
  padding: 20px;
  overflow: auto;
}

.comment-modal .right-section .btn-close {
  padding: 0;
  position: initial;
  margin: initial;
}

.comment-modal .modal-content {
  height: 612px;
}

.teaser-modal .modal-content {
  overflow: hidden;
}

.teaser-modal .modal-content video {
  object-fit: fill;
}

/***** donate modal********/
.donate-modal .modal-dialog {
  max-width: 647px;
  width: 100%;
  height: 689px;
}

.donate-modal .modal-content {
  background: #ffffff;
  border-radius: 30px;
}

.donate-modal .modal-content .modal-body,
.donate-modal .tip-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.donate-modal .modal-content .modal-body {
  padding: 30px 0px;
}

.donate-modal .modal-content .modal-body button,
.donate-modal .modal-content .modal-body button:hover,
.donate-modal .modal-content .modal-body button:focus {
  width: 156px;
  height: 49px;
  margin-top: 35px;
  background: #0296dd;
  border: 1px solid #0296dd;
  border-radius: 12px;
}

.donate-modal .tip-section .choose-option-list {
  margin: 50px 0px 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
}

.donate-modal .tip-section .choose-option-list .select-option {
  width: 112px !important;
  height: 48px !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd !important;
  opacity: 0.6;
  color: #000;
  border-radius: 32px !important;
  background: transparent;
}

.donate-modal .tip-section .choose-option-list .select-option.active {
  background: #0296dd;
  color: #ffffff;
  opacity: 1;
}

.donate-content {
  width: 100%;
}

.donate-modal .tip-section input {
  width: 403px;
  height: 58px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5b5b5b;
  text-align: center;
  background: #e2e2e2;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
}

.donate-modal .user-details img {
  height: 95px;
  width: 95px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
}

.donate-modal .user-details h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #3b3b3b;
  margin: 0;
}

.donate-modal .user-details {
  padding-bottom: 30px;
  /* border-bottom: 1px solid #e2e2e2; */
  margin-bottom: 25px;
}

.donate-modal .tip-section h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #3b3b3b;
  margin-bottom: 15px;
}

.donate-modal .tip-section p {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0296dd;
}

.donate-modal .tip-section h1 {
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  line-height: 49px;
  text-align: center;
  color: #0296dd;
}

/**** audio popup ******/
.audio-popup .file-box.file-label {
  height: 403px;
}

.audio-popup .file-label.file-label-sm.mb-md-0 {
  height: 132px !important;
  padding: 0;
}

.audio-popup .modal-content {
  height: 605px !important;
  width: 100%;
  max-width: 1039px;
}

.audio-popup .caption-control {
  padding: 15px;
}

.audio-popup .radio-price-group {
  flex-wrap: nowrap;
}

.audio-popup .right-section {
  display: flex;
  flex-direction: column;
}

.audio-popup .file-label {
  height: 403px !important;
}

/******** referral **********/
.referral-box {
  width: 100%;
  height: 498px;
  background: #f0f0f0;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

/******* paid media package*************/
.paid-media-popup h5 {
  text-align: center;
  font-weight: 700;
  border-bottom: 1px solid #e1e1e1;
  width: calc(100% + 48px);
  margin-left: -24px;
  /* margin-bottom: 20px; */
  /* padding-bottom: 14px; */
}

.paid-media-popup .paid-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
}

.paid-media-popup .paid-list p {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}

.paid-media-popup .paid-list button,
.paid-media-popup .paid-list button:hover,
.paid-media-popup .paid-list button:focus {
  background: #0296dd;
  font-size: 12px;
  height: 27px;
  border: 1px solid #0296dd;
  padding: 0;
  min-width: 59px;
}

/******* delete popup **********/
.delete-popup .modal-body {
  padding: 40px;
}

.delete-popup .modal-content {
  border-radius: 20px;
  border: 1px solid white;
}

.delete-popup .modal-body .delete-popup-content h6 {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
}

.delete-popup .modal-body .delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.delete-popup .modal-body .delete-btn button:first-child {
  background: #0296dd;
  border-radius: 12px;
  padding: 8px 24px;
  min-width: 120px;
}

.delete-popup .modal-body .delete-btn button:last-child {
  background: transparent;
  min-width: 120px;
  padding: 8px 24px;
  border: 1px solid #9f9a9a;
  color: #000000;
}

.delete-popup .modal-body .delete-btn button {
  font-weight: 600;
}

/******* story-popup************/

.story-popup .modal-body .create-story-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.story-popup .modal-body .create-story-content img {
  width: 156px;
}

.story-popup .create-story-content h6 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #111111;
  margin: 69px auto 51px;
  max-width: 206px;
}

.story-popup .create-story-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8d8d8d;
  max-width: 206px;
  width: 100%;
  margin: 0px auto;
}

.story-popup .create-story-content label {
  color: #3f3f3f;
  background: transparent;
  border: 1px solid #3f3f3f;
  border-radius: 12px;
  max-width: 232px;
  width: 100%;
  height: 54px;
  margin: 74px auto 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.story-popup .upload-img {
  width: 100%;
}

.story-popup .create-story-content input {
  display: none;
}

.story-popup .modal-content {
  width: 436px;
  height: 650px;
  background: #ffffff;
  border-radius: 30px;
}

.uploaded-story-content .user-details img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.uploaded-story-content .user-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 11px 25px;
  align-items: center;
}

.uploaded-story-content .left-content {
  display: flex;
  align-items: center;
}

.uploaded-story-content .left-content h6 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #3b3b3b;
}

.uploaded-story-content .left-content p {
  margin: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #969696;
}

.uploaded-story-content .user-details i {
  color: #a7a7a7;
  font-size: 28px;
  cursor: pointer;
}

.uploaded-story-content .slick-slider {
  height: calc(100% - 54px);
}

.uploaded-story-content .slick-slider img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px 15px 30px 30px;
}

.uploaded-story-content ul.slick-dots li button {
  height: 5px;
  width: 31px;
  padding: 0px !important;
  background: #ffffff;
  border-radius: 8px;
}

.uploaded-story-content ul.slick-dots li button::before {
  display: none;
}

.uploaded-story-content ul.slick-dots li {
  margin: 0px 10px;
}

.uploaded-story-content ul.slick-dots {
  position: absolute;
  top: 0;
  height: fit-content;
}

.upload-story-content .uploaded-story-content {
  height: 100%;
}

.uploaded-modal .slick-track,
.uploaded-story-content,
.uploaded-modal .slick-list,
.uploaded-modal .slick-slide * {
  height: 100% !important;
}

.uploaded-story-content button.slick-arrow.slick-prev {
  display: none !important;
}

.uploaded-story-content .slick-next:before {
  opacity: 1 !important;
  font-size: 30px;
}

.total-views {
  min-width: 66px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: fit-content;
  margin: 0px auto;
  padding: 8px 16px;
  position: absolute;
  bottom: 21px;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
}

.total-viewers-popup {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: auto;
  height: 0px;
  opacity: 0;
  transition: all ease 0.5s;
}

.total-viewers-popup.slide-view-popup {
  height: 388px;
  opacity: 1;
}

.total-viewers-popup::-webkit-scrollbar {
  width: 0px;
}

.total-viewers-popup::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.total-viewers-popup::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.upload-story-popup .multiple-img::-webkit-scrollbar {
  height: 5px;
}

.upload-story-popup .multiple-img::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.upload-story-popup .multiple-img::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.total-viewers-popup .viewers-box img {
  height: 42px;
  width: 42px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.viewers-box {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #111111;
  padding: 10px 25px;
}

/************  unlcok popup ***********/
.unlock-msg-view {
  max-width: 200px;
  height: 150px;
  position: relative;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  margin-bottom: 10px;
}

.unlock-msg-view img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.unlock-msg-view .unlock-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.unlock-msg-view .unlock-btn button {
  background: white;
  font-size: 12px;
  border: 1px solid white;
  color: black;
  font-weight: 600;
  padding: 5px 20px;
}

.unlock-msg-view .unlock-btn i {
  font-size: 35px;
  color: white;
  margin-bottom: 10px;
}

.unlock-msg-view .ul-img {
  position: relative;
  height: 100%;
  width: 100%;
}

.unlock-msg-view .ul-img::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0000009e;
  z-index: 9;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  filter: blur(1px);
  border-bottom-left-radius: 0px;
}

.unlock-msg-view-open img {
  filter: blur(10px);
}

/********** upload story popup *********/

.upload-story-popup .modal-body {
  padding: 0;
}

.upload-story-popup .upload-story-content .uploadedimg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 30px 30px 0px 0px;
  background-size: auto;
  z-index: 1;
  display: flex;
  position: absolute;
}

.upload-story-popup .upload-story-content .uploadedimg {
  width: 100%;
  height: calc(100% - 170px);
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15));
  border-radius: 30px 30px 0px 0px;
  background: #c9c9c9;
  position: relative;
  overflow: hidden;
}

.upload-story-popup .upload-story-content .uploadedimg .delete-img-btn {
  background: #000;
  position: absolute;
  border-radius: 50%;
  border: none;
  outline: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  right: 10px;
  bottom: 5px;
  z-index: 4;
}

.upload-story-popup .modal-body {
  height: 100%;
}

.upload-story-content {
  height: 100%;
}

.upload-story-popup .upload-more .story-img-t {
  max-width: 80px;
  width: 100%;
  height: 80px;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15));
  border-radius: 12px;
  object-fit: cover;
  border: none;
  outline: transparent;
}

.upload-story-popup .upload-more .story-img-t img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.upload-story-popup .upload-more {
  padding: 19px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.upload-story-popup .multiple-img {
  display: flex;
  max-width: calc(100% - 80px);
  overflow: auto;
  gap: 12px;
}

.upload-story-popup .add-more {
  max-width: 80px;
  width: 100%;
  height: 80px;
  border: 2px solid #000000;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15));
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: transparent;
  margin-bottom: 0;
}

.upload-story-popup .add-more i {
  font-size: 45px;
  color: black;
}

.unlock-content-popup h6 img {
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.unlock-content-popup .coins {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0b3;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 20px auto 30px;
  padding: 5px 20px;
  border-radius: 30px;
  max-width: 200px;
  width: 100%;
}

.unlock-content-popup .uer-details {
  text-align: center;
  padding: 30px 0px 5px;
}

.unlock-content-popup .uer-details h6 {
  font-weight: 700;
  margin: 10px 0px;
}

.unlock-content-popup .uer-details img {
  height: 55px;
  width: 55px;
  object-fit: cover;
}

/*******text post modal ********/
.text-post-modal .modal-body {
  padding: 25px !important;
}

.text-post-modal textarea {
  width: 100%;
  min-height: 235px;
  background: #e2e2e2;
  border-radius: 30px;
  padding: 25px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 142.5%;
  color: #111111;
}

.text-post-modal textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 142.5%;
  color: #111111;
}

.text-post-modal button,
.text-post-modal button:hover,
.text-post-modal button:focus {
  width: 100%;
  height: 60px;
  background: #0296dd;
  border: 1px solid #0296dd;
  border-radius: 12px;
  margin-top: 70px;
}

.text-post-modal input,
.text-post-modal input:hover,
.text-post-modal input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.text-post-modal input,
.text-post-modal input::placeholder {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #9f9f9f;
  padding-bottom: 0px;
}

.text-post-modal label.btn.btn-secondary {
  width: fit-content;
  max-width: fit-content;
}

.text-post-modal .radio-price-group-circle {
  justify-content: flex-start !important;
  padding: 0px;
  margin: 35px 0px !important;
}

.text-post-modal .caption-label {
  padding: 5px 0px !important;
  margin: 0;
}

.select-box__control,
.select-box__control:hover,
.select-box__control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.select-box__indicators {
  display: none !important;
}

.text-post-modal .modal-content,
.text-post-modal .modal-body {
  height: fit-content !important;
}

.unlock-content-popup h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.unlock-content-popup h4 p {
  margin: 0;
}

.post-modal.file-upload.unlock-popup.unlock-content-popup.show-post-modal {
  padding-top: 0 !important;
}

.unlock-content-popup h4 i {
  font-size: 30px;
  cursor: pointer;
}

/********** explore list **************/
.explore-tab-list {
  background: #ffffff;
  border-radius: 25.4032px;
}

.explore-tab-list ul li button {
  background: transparent;
}

.explore-tab-list ul {
  margin: 0 !important;
}

.explore-tab-list ul li.nav-item button {
  width: 100%;
  padding: 18px 0px 14px;
  font-weight: 600;
  font-size: 16px;
  line-height: 110.5%;
  color: #aeaeae;
}

.explore-tab-list .nav-tabs .nav-link.active {
  border: none !important;
  border-bottom: 2px solid #676767 !important;
  background: transparent;
  color: #676767;
}

.explore-tab-list ul li.nav-item button:hover,
.explore-tab-list ul li.nav-item button:focus {
  border: none !important;
}

.explore-tab-list ul li.nav-item {
  width: 33%;
}

.explore-tab-list .box-container {
  padding: 0 !important;
}

.explore-tab-list .post-list-box .box-container {
  margin: 0;
}

.explore-tab-list .post-list-box {
  padding: 30px;
}

.people-list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.people-list {
  padding: 20px 30px 30px;
}

.people-list-box .people-details {
  display: flex;
  align-items: center;
}

.people-list-box .people-details h6 {
  margin: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #111111;
}

.people-list-box .people-details img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.people-list-box button,
.people-list-box button:hover,
.people-list-box button:focus {
  width: 76px;
  height: 27px;
  border: 1px solid #0296dd;
  font-weight: 600;
  font-size: 12px;
  line-height: 110.5%;
  text-align: center;
  color: #0296dd;
  border-radius: 5px;
  background: transparent;
  padding: 0;
}

.tag-list-section {
  padding: 20px 30px 30px;
}

.tag-box {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.tag-box h6 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
}

.tag-box p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #a2a2a2;
}

.tag-box img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

/************** show image popup ************/
.show-imge-popup .modal-body,
.show-imge-popup .modal-content {
  padding: 0;
  border-radius: 12px;
}

.show-imge-popup .modal-body img {
  border-radius: 12px;
}

.banking-page .text-danger {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}

.coin-input {
  text-align: center;
}

.dollor-sign {
  cursor: pointer;
}

/* VIEW FOLLOWER */
.view-follower-modal .modal-content {
  border-radius: 30px;
  min-width: 396px;
  margin: 35px;
}

.follower-following-height-scroll {
  min-height: 550px;
  max-height: 550px;
  overflow: auto;
}

.follower-following-height-scroll::-webkit-scrollbar {
  width: 0;
}
.follower-following-height-scroll-following-modal {
  min-height: 550px;
  max-height: 550px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.follower-following-height-scroll-following-modal::-webkit-scrollbar {
  width: 0;
}
/* VIEW STORY MODAL STYLES */
.view-story-modal .modal-dialog .modal-content {
  border-radius: 30px;
}

.view-story-modal-body {
  max-width: 336px;
  height: 647px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.more-btn-icon {
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-story-modal-body .story-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 25px;
}

.story-modal-header .author-img {
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0px;
  font-weight: 600;

  background: linear-gradient(180deg, #36bdff 0%, #0085c5 100%);

  font-size: 12px;
  color: #fff;
}

.story-modal-header .story-author {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.story-author .author {
  display: flex;
  flex-direction: column;
}

.author p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3b3b3b;
}

.author span {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #969696;
}

.view-story-modal-body .story-modal-slider {
  flex: 1;
  width: 100%;
  position: relative;
  border-radius: 15px 15px 30px 30px;
  overflow: hidden;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  background-size: cover;
}

.prev-story {
  left: 0;
  top: 0;
  height: 100%;
}

.nxt-story {
  right: 0;
  top: 0;
  height: 100%;
}

.prev-story,
.nxt-story {
  opacity: 0;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  padding: 0;
}

.story-modal-slider .story-img {
  background-position: center center;
  z-index: 3;
}

.story-progress-bars {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
  margin-top: 10px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.story-progress-bars .img-progress-bar {
  height: 4px;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.img-progress-bar .img-internal-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #fff;
  border-radius: 8px;
}

.view-story-modal-body .next-story-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 105%;
  height: 29px;
  width: 29px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: #fff;
}

/* VIEW STORY MODAL STYLES END */

.unlock-post-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  outline: none;
  background-color: transparent;
}

.unlock-pot-modal-body .uer-details {
  text-align: center;
  padding: 30px 0px 5px;
  margin-top: 40px;
}

.unlock-pot-modal-body .uer-details h6 {
  font-weight: 700;
  margin: 30px 0px;
  font-size: 34px;
}

.unlock-username {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0296dd;
}

.unlock-pot-modal-body .uer-details img {
  height: 105px;
  width: 105px;
  object-fit: cover;
}

.unlock-post-btn {
  background-color: #0296dd;
  outline: none;
  border: none;
  padding: 15px 25px;
  margin-top: 30px;
  border-radius: 10px;
  font-size: 22px;
  color: #fff;
}

.reply-to-text {
  width: 100%;
  background-color: rgba(129, 129, 129, 0.4);
  color: #000;
  padding-left: 15px;
  font-size: 12px;
}

.post_audio_cont {
  padding: 45px;
}

.post_audio_cont {
  padding: 45px;
}

.post_audio_cont::before {
  content: "";
  height: 100%;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: absolute;
  top: 0;
  left: 0;
  /* backdrop-filter: blur(20px); */
}

.post_audio_contant-feedpost::before {
  content: "";
  height: 100%;
  width: auto;
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: absolute;
  top: 0;
  left: 0;
  /* backdrop-filter: blur(20px); */
}

.post_audio_btn {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: -8px -8px 20px rgba(122, 134, 151, 0.148847),
    8px 8px 20px rgba(0, 0, 0, 0.301983);
  border: 5px solid black;
}

.audio-post-modal-container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}

.audio-thumb {
  background-color: #e2e2e2;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-thumb img {
  width: 200px;
  height: 200px;

  border-radius: 50% !important;
  overflow: hidden;
  cursor: pointer;
  box-shadow: -8px -8px 20px rgba(122, 134, 151, 0.148847),
    8px 8px 20px rgba(0, 0, 0, 0.301983);
  border: 5px solid black;
}

.audio-sound {
  background-color: #e2e2e2;
  flex: 1;
  width: 100%;
  border-radius: 30px;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 27px;
}

.sound-control {
  flex: 1;
}

.sound-play-btn {
  margin-right: 20px;
}

.input-range__label {
  display: none !important;
}

.audio-player-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.audio-slider {
  width: 100%;
}

.audio-play-btn {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(213.5deg, #36bdff 11.35%, #3662ff 86.53%);
  box-shadow: 0px 12px 40px rgba(54, 98, 255, 0.25), inset 4px 12px 40px #36bdff;

  border-radius: 50%;
  border: none;
  outline: none;

  margin-right: 15px;
}

.audio-cover-img {
  width: 251px;
  height: 251px;
  left: 318px;
  top: 371px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: -8px -8px 20px rgba(122, 134, 151, 0.148847),
    8px 8px 20px rgba(0, 0, 0, 0.301983);
  border: 5px solid black;
  cursor: pointer;
}

.input-range__track--active {
  background: #36bdff !important;
}

.input-range__slider {
  background: #36bdff !important;
  border: 1px solid #36bdff !important;
  margin-top: -0.72rem !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.input-range__track {
  height: 8px !important;
}

.audio-post-container {
  padding: 0px 30px;
  flex: 1;
  position: relative;
}

.change-audio-btn {
  position: absolute;
  right: 30px;
  bottom: -25px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #0296dd;

  cursor: pointer;
}

.slick-dots {
  bottom: -26px !important;
}

.post-image-comment .slick-dots {
  bottom: -26px !important;
}

/* latest modification */
.multiple-img .slick-list {
  width: 280px;
  height: 80px;
  padding-right: 209px;
  background-color: transparent;
}

.swiper.swiper-slider .slick-list {
  max-width: 500px;
  width: 100%;
}

/* .multiple-img .slick-arrow .slick-next{
  width: 21px;
  display: block;
} */
.multiple-img .slick-arrow {
  display: block;
  width: 20px;
  border: none;
}

.multiple-img .slick-slide button,
.multiple-img .slick-arrow {
  background: transparent;
}

.multiple-img .slick-slide {
  padding: 0 5px;
}

.multiple-img .slick-arrow.slick-next:before {
  background: url("../images/icons/right-arrows.png") center no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
  content: "";
}

.blurbackground {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(10px);
  border-radius: 30px 30px 0px 0px;
}

.swiper-slide .slick-track .slick-slide {
  min-width: 80px;
  max-width: max-content;
}

.blureffect {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(20px);
  border-radius: 30px 30px 0px 0px;
  background: #f2e8e833;
}

.story-img img {
  height: auto;
  width: 100%;
}

/* html,body{height:100%;} */
.file-carousel .carousel,
.file-carousel .item,
.active {
  height: 100%;
}

.file-carousel .carousel-inner {
  height: 100%;
}

.file-carousel .carousel-inner > .item > img {
  height: 100%;
}

.file-label.file-carousel > div {
  height: inherit;
}

.lock_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  flex-direction: column;
}
.lock_icon-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: auto;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 30%;
  flex-direction: column;
}

.btn-lock.btn-lock_img {
  padding: 0.34rem 0.99rem;
  background-color: white;
}

.file-label.file-carousel {
  position: relative;
  overflow: hidden;
}

.file-label.file-carousel:has(.reactEasyCrop_Container),
.file-label.file-carousel:has(.cropedimg) {
  background: transparent !important;
}

.file-label.file-carousel:has(.cropedimg) div {
  display: flex;
  align-items: flex-start;
}

.reactEasyCrop_Container,
.cropedimg {
  border-radius: 20px;
  width: 702px;
  height: auto;
}

.reactEasyCrop_Container {
  margin: 0 0 60px 0;
  background: transparent;
}

.buttoncrop.btn {
  bottom: 0px;
  position: relative;
  z-index: 40;
  background: #0296dd;
  border-color: #0296dd;
  border-radius: 10px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.payment-form .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  border: 1px solid #e2e2e2;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.payment-form label {
  max-width: 500px;
  width: 100%;
}

.payment-form button {
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0296dd;
  transition: all 150ms ease;
  margin-top: 10px;
}

.comment-modal .modal-content:has(.payment-form),
.custom-modal.comment-modal .modal-body:has(.payment-form) {
  max-height: 300px;
  height: 100%;
}

.custom-modal.comment-modal .modal-dialog:has(.payment-form) {
  max-width: 600px;
  width: 100%;
  max-height: 300px;
  height: 100%;
}

.caption-view {
  margin-top: 25px;
  margin-bottom: 1rem;
  width: auto;
  white-space: break-spaces !important;
  background: #f5f5f5;
  border-radius: 12px;
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 142.5%;
  color: #000000;
  padding: 10px;
}

.item-price-position {
  position: absolute !important;
  right: 20px !important;
}

/* to remove the type="number" icon from input box*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.img-fluid.preview {
  max-height: 562px;
  max-width: 562px;
  border-radius: 30px;
  object-fit: cover;
}

.transparent-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff91;
  z-index: 99999999999999 !important;
  /* Set a high z-index value to ensure it is above the content */
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.following-page-title {
  font-family: Montserrat !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}

.following-page-title-div {
  justify-content: space-between;
  border-bottom: 2px solid #e2e2e2;
  padding-bottom: 15px;
  margin-top: 20px;
}

.golive-modal .live-btn {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  cursor: pointer;
}

.golive-modal .live-btn span {
  display: block;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background: #f20c0c;
  border: 3px solid #fff;
  box-shadow: 0 0 0px 5px #000000;
  margin-bottom: 15px;
}

.golive-modal .scroll-box {
  overflow: auto;
  max-height: 450px;
  margin-bottom: 20px;
}

.golive-modal .scroll-box::-webkit-scrollbar {
  width: 10px;
}

.golive-modal .scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.golive-modal .scroll-box::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.exit-btn {
  position: absolute;
  right: 20px;
  top: 0px;
  border: 0;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 13px;
  background: #828282;
  border-radius: 30px;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 99;
}

.golive-modal .modal-body {
  padding-top: 50px;
}

.golive-modal .amount-label {
  background: #f0f0f0;
  margin-bottom: 0px !important;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
}

.golive-modal .cmt-img {
  max-height: 600px;
}

.paid-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.stream-comment {
  padding: 8px 20px 20px;
  background: #eaeaea;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: calc(100% - 40px);
}

.stream-comment .stream-body {
  flex: 1;
  overflow: auto;
}

.stream-comment .stream-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
  border-bottom: 1px solid #dadada;
  padding: 10px 10px 15px;
  margin: 0 -20px;
}

.stream-comment .stream-footer {
  border: 1px solid #c2c2c2;
  border-radius: 40px;
  width: 100%;
  margin: 15px auto 0;
}

.stream-comment .stream-footer .post-user-control {
  padding: 8px 8px !important;
}

.stream-comment .stream-footer .btn {
  margin-right: -10px;
}

.golive-modal .view-btn {
  background: linear-gradient(92.63deg, #3662ff 0.71%, #36bdff 100.85%);
  border-radius: 15px;
  padding: 15px;
  color: #fff;
  text-align: center;
  width: calc(100% - 40px);
  margin: 60px auto 0px;
}

.golive-modal .stream-comment .post-user-control {
  padding: 14px 13px 0;
}

.golive-modal .stream-comment .post-user-control .post-user-name {
  line-height: 20px;
}

.golive-modal .stream-comment .post-user-control .text-msg {
  font-size: 12px;
  line-height: 15px;
}

.live-search {
  display: flex;
  background: #d3d3d3;
  border-radius: 8px;
  padding: 8px;
  margin: 15px 0 0;
}

.live-search input,
.stream-comment .stream-footer input {
  padding: 0;
  border: 0;
  background: transparent;
  outline: none !important;
}

.golive-modal.modal-dialog-scrollable .modal-content {
  overflow: hidden;
}

.subscribe-box .sub-amount {
  font-weight: 600;
  font-size: 36px;
  text-align: right;
  color: #0296dd;
  margin: 0px;
}

.subscribe-box .dayslabel {
  background: #a7a7a7;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
}

.subscribe-box .date-label {
  font-weight: 500;
  font-size: 14px;
  color: #111111;
  margin: 5px 0;
}

.subscribe-box {
  border: 1px solid #0296dd;
  border-radius: 13px;
  padding: 10px 20px 20px;
  position: relative;
  margin-bottom: 20px;
}

.subscribe-box:last-child {
  margin-bottom: 0;
}

.subscribe-box .post-user-control {
  padding: 20px 0 10px;
}

.subscribe-box .post-user-control .post-user-logo.post-user-logo-sm {
  width: 70px;
  height: 70px;
}

.subscribe-box .post-user-control .post-user-logo.post-user-logo-sm .img-fluid {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-common-user-header-profile-name-log {
  margin-right: 0px !important;
  margin-left: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reciever-user-message-profile-username {
  text-overflow: ellipsis;
  font-size: 16px !important;
  line-height: 18px !important;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700 !important;
  height: auto;
  color: black !important;
  justify-content: flex-start;
  /* display: flex;   */
}
.subscribe-box .post-user-control .post-user-name {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 6px;
}

.subscribe-box .plan-label {
  background: #0296dd;
  border-radius: 0 0px 14px 14px;
  padding: 6px 9px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 0;
}

.past-subscribe-box {
  border: 1px solid #b7b7b7;
}

.subscribe-box.past-subscribe-box .plan-label {
  background: rgba(2, 150, 221, 1);
}

.subscribe-box.past-subscribe-box .date-label,
.subscribe-box.past-subscribe-box .sub-amount {
  color: #b7b7b7;
}

.wallet-box {
  padding: 20px 30px;
  background: linear-gradient(93.69deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-box .balance-labels {
  font-weight: 500;
  font-size: 16px;
  color: #f0f0f0;
  margin: 0;
}

.wallet-box .wallet-amount {
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  margin: 0;
}

.gray-label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #838383;
  margin: 30px 0;
}

.coin-slider .swiper-slide {
  width: 131px;
}

.coin-box {
  border: 1px solid #0296dd;
  border-radius: 13px;
  padding: 15px 15px 0;
  text-align: center;
}

.coin-box .dollar-sign {
  width: 52px;
}

.coin-box p {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin: 0 0 15px;
}

.coin-box .price {
  padding: 9px;
  color: #fff;
  background: #0296dd;
  border-radius: 0px 0px 11px 11px;
  margin: 0 -15px;
  text-align: center;
}

.coin-slider {
  overflow: hidden;
  width: calc(100% + 30px);
}

.history-box {
  padding: 30px;
  background: #f0f0f0;
  border-radius: 13px;
}

.history-box .history-content {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.history-box .history-content:last-child {
  margin-bottom: 0;
}

.history-content .date-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 11px 13px;
  background: #ececec;
  border-radius: 10px;
  font-weight: 700;
  font-size: 26px;
  color: #111111;
  width: 66px;
  line-height: 22px;
  margin-right: 15px;
}

.history-content .date-icon span {
  font-weight: 400;
  font-size: 16px;
  color: #111111;
}

.history-content .name {
  font-weight: 700;
  font-size: 20px;
  color: #111111;
  margin: 0;
}

.history-content p {
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
  margin: 2px 0;
}

.history-content .price {
  font-weight: 600;
  font-size: 24px;
  color: #059d14;
  margin: 0 10px 0 0;
}

.history-content .price-danger {
  color: #c30a0a;
}

.history-box .transaction-btn {
  font-weight: 600;
  font-size: 16px;
  color: #0296dd;
  padding: 15px 10px;
  text-align: center;
  display: block;
  border-top: 1px solid #b5b5b5;
  width: calc(100% + 60px);
  margin: 0 -30px -30px;
  border-radius: 0;
}

.plan-box {
  padding: 25px;
  background: #f0f0f0;
  border-radius: 13px;
  margin-bottom: 25px;
}

.plan-box .plane-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
}

div.switcher label input {
  display: none;
}

div.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 50px;
  height: 12px;
  background: #ffffff;
  box-shadow: inset 0px 0px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

div.switcher label input + span small {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  width: 26px;
  height: 26px;
  background: #ffffff;
  border: 2px solid #0296dd;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  top: -7px;
  left: -4px;
}

div.switcher label input:checked + span {
  background: #0296dd;
  box-shadow: inset 0px 0px 1.5px rgba(0, 0, 0, 0.5);
}

div.switcher label input:checked + span small {
  left: 50%;
  background: #0296dd;
  border-color: #fff;
}

.price-group {
  width: 135px;
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 7px 10px;
  border-radius: 10px;
}

.price-group .input-group-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #767676;
  padding: 0;
  width: 20px;
  background: transparent;
  border: 0;
}

.price-group .form-control {
  padding: 0 !important;
  border: 0;
}

.inputlabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #111111;
  margin: 20px 0 10px;
}

.active-plan-box {
  background: linear-gradient(94.48deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
}

.active-plan-box .plane-title,
.active-plan-box .inputlabel {
  color: #fff;
}

.active-plan-box div.switcher label input + span small {
  background: #0296dd;
  border: 2px solid #fff;
}

.package-btn {
  background: linear-gradient(91.59deg, #3662ff 0%, #36bdff 100%);
  border-radius: 13px;
  padding: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border: 0;
  width: 100%;
}

.package-btn img {
  filter: brightness(0) invert(1);
}

.package-wrapper {
  border-top: 1px solid #e2e2e2;
  margin-top: 30px;
  padding-top: 30px;
}

.package-plan {
  background: #7739ff;
  border-radius: 12px;
  padding: 28px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.package-plan:last-child {
  margin-bottom: 0;
}

.package-plan .pc-label {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.package-plan .p-title {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}

.package-plan .share-btn {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  height: 55px;
}

.package-plan-orange {
  background: #ff7246;
}

.package-plan-pink {
  background: #ff4689;
}

.package-plan-green {
  background: #14bc8a;
}

.package-modal .modal-title {
  font-weight: 600;
  font-size: 22px;
  color: #111111;
  text-align: center;
}

.active-list {
  background: #e7f7ff;
}

.search-package-list {
  margin: 0 -16px;
  position: relative;
  padding-bottom: 60px;
}

.search-package-list .post-user-control {
  padding: 9px 13px;
}

.package-save-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 78.65%
  );
  border-radius: 0px 0px 30px 30px;
  padding: 10px 0 30px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.package-save-btn .btn-login {
  width: 135px;
  margin: 0 auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.package-modal .modal-dialog-scrollable .modal-content {
  overflow: hidden;
}

.package-modal .modal-dialog-scrollable .modal-body {
  max-height: 530px;
}

.package-form {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 25px;
  text-align: center;
}

.package-form label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #111111;
  margin-bottom: 20px;
}

.package-form .form-control {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  max-width: 392px;
  margin: 0 auto 30px;
}

.package-form .btn {
  width: 160px;
  margin: 0 auto;
}

.select-media {
  background: #f0f0f0;
  border-radius: 13px;
  padding: 20px;
  display: block;
  margin: 20px 0;
}

.select-media .border {
  border: 1px dashed #838383 !important;
  border-radius: 8px;
  padding: 33px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #111111;
}

.package-img {
  max-width: 100%;
  margin: 20px auto;
  border-radius: 18px;
}

.audio-box {
  padding: 90px 20px;
  background: #f5f5f5;
  border-radius: 18px;
}

.audio-thumbnail.audio-thumbnail-relative {
  position: relative;
  top: auto;
  left: auto;
  transform: unset;
  width: 258px;
  margin: 0 auto;
}

.subscribe-box.trial-plan {
  border: 1px solid #3662ff;
}

.subscribe-box.trial-plan .plan-label {
  background: #3662ff;
}

.subscribe-box.trial-plan .sub-amount {
  color: #3662ff;
}

.subscribe-box .btn.text-danger {
  color: #f20c0c !important;
  font-size: 12px;
}

.subscribe-box .btn.text-info {
  font-size: 12px;
  color: #0296dd;
}

.subscribe-box.trial-plan {
  padding-bottom: 50px;
}

.subscribe-box .trial-text {
  background: #3662ff;
  border-radius: 10px 10px 0px 0;
  color: #fff;
  padding: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 90%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
}

.donate-btn {
  padding: 11px 40px !important;
  height: auto;
}

.form-control.bg-opacity-100:focus {
  background-color: #f0f0f0;
}

.live-title-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 1.5rem;
  width: 100%;
  /* border-radius: 0px 0px 30px 30px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.live-title-overlay h3 {
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 0px;
}

.live-title-overlay p {
  color: #fff;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.cmt-img-wrapper {
  border-radius: 4%;
  padding: 0px;
}

.story-user-live-logo {
  background-color: #c51c1c;
  border-radius: 2px;
  text-align: center;
  width: 34px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  height: 14px;
  z-index: 11;
  margin-top: -8px;
  margin-left: 12px;
}

.story-image-for-live {
  margin-top: 2px !important;
}

.new-live-user {
  border: 0.725806px solid #0085c5;
  background-color: #fff;
}

.comment-text-modal-white-space {
  white-space: break-spaces;
}
.my-profile-user-details-background {
  background-color: white !important;
}

.player div {
  /* border-radius: 30px; */
  height: 100%;
}
.player {
  width: 100% !important;
  height: 100vh !important;
  /* height: auto !important;
  width: auto !important; */
}
.player-full-screen-contain video {
  object-fit: cover !important;
  /* padding-bottom: 53px; */
  padding: 0px 8% 53px 8%;
}
.player-full-screen-cover video {
  object-fit: cover !important;
  padding-bottom: 53px;
}
/* 
.player video {
  position: relative !important;
  height: auto !important;
  width: auto !important;
} */

.player video {
  object-fit: contain !important;
}

.player-name {
  display: none;
}

.audience-btn-container {
  background: linear-gradient(90.86deg, #3662ff 0.6%, #36bdff 101.55%);
  padding: 12px 28px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin-top: 38px;
}

.live-chat-container {
  margin-top: 12px;
  background-color: #eaeaea;
  border-radius: 15px;
  /* height: 100%; */
}

.host-live-view {
  background: linear-gradient(90.86deg, #3662ff 0.6%, #36bdff 101.55%);
  padding: 12px 28px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.message-send-btn-container input {
  border-left: 0px;
  border-right: 0px;
}

.message-send-btn-container input:hover {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}

.message-send-btn-container span {
  background-color: #ffffff;
  border-radius: 50%;
  padding-right: 0px;
}

.message-send-btn-container div {
  background-color: #ffffff;
}

.remote-playerlist-contain video {
  object-fit: cover !important;
  transform: rotateY(180deg) !important;
  padding: 0px 8% 53px 8%;
}

.remote-playerlist-cover video {
  object-fit: cover !important;
  /* transform: rotateY(180deg) !important; */
  padding-bottom: 53px;
}

.chat-message-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chat-message-container::-webkit-scrollbar {
  display: none;
}
.subscribe-confirm-modal {
  display: flex;
  justify-content: flex-end;
}
.subscribe-confirm-modal button {
  margin-right: 5px;
}
.subscription-modal-main-div .modal-dialog .modal-content {
  border-radius: 20px;
  width: auto;
}
.no-subscription-message {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.header-searchbar-no-result-found {
  font-weight: 500;
  font-size: 12px;
}

.join-modal div .modal-content {
  padding: 0px !important;
}

.join-modal .modal-dialog {
  max-width: 100%;
}

.popover-container {
  position: absolute;
  z-index: 100;
  top: 30px;
  left: -80px;
  background-color: #ffffff;
  height: auto;
  width: auto;
  /* padding: 0px 12px 12px 12px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 260px;
  border-radius: 30px;
}

.tip-container {
  display: flex;
  justify-content: space-around;
}

.tip-box {
  padding: 2px 8px;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
  text-align: center;
  color: #9d9d9d;
}
.tip-box:hover {
  background-color: #1099da9e;
}

.tip-box-active {
  padding: 2px 8px;
  background-color: #0296dd;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
  text-align: center;
  color: #ffffff;
  font-size: 500;
}

.tip-custom-input {
  background: #f7f7f7;
  border: 0.725806px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
  height: 37px;
  width: 100%;
  text-align: center;
}

.custom-tip-btn {
  background-color: #0296dc !important;
  padding: 1px 16px;
  color: #ffffff;
  font-weight: 500;
  color: #ffffff !important;
  font-size: 12px !important;
}

.tip-profile-title {
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.tip-profile-userd-name {
  margin: auto;
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
  color: #3662ff;
}

.tip-display {
  font-size: 22px;
  font-weight: 600;
  color: #3662ff;
  padding: 3px 16px;
  border-radius: 30px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
}

.tip-error {
  font-size: 12px;
  color: red;
  margin-top: 2px;
}
.edit-profile-text-error-message {
  font-size: 12px;
  font-weight: 500;
  margin-left: 25px;
  text-align: left;
}
.error-main-div {
  height: 22px;
  align-items: center;
  display: flex;
}
.sb-avatar__image img {
  position: absolute !important;
  top: 0px !important;
}
.searchble-user-name {
  font-size: 12px !important;
  margin-bottom: 1px;
  color: black !important;
  font-weight: 700;
}
.searchble-user-name-firstname {
  font-size: 12px !important;
  margin-bottom: 1px;
  font-weight: 600;
}
.search-user-list-image img {
  position: inherit !important;
  top: 0 !important;
}
.dropdown::-webkit-scrollbar {
  display: none;
}
.langauge-validation {
  flex-wrap: wrap !important;
}
.full-screen {
  position: relative;
  right: 0px;
  white-space: nowrap;
  margin-left: 10px;
}
.website-link-user-profile {
  text-decoration: underline !important;
}
.live-stream-pay-container {
  top: 30px;
  left: -80px;
  background-color: #ffffff;
  height: auto;
  width: auto;
  /* padding: 0px 12px 12px 12px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 260px;
  border-radius: 30px;
}

.pay-stream-profile-title {
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

.pay-stream-profile-userd-name {
  margin: auto;
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
  color: #3662ff;
  margin-bottom: 22px;
}

.pay-stream-amonut-btn {
  background-color: #0296dc !important;
  padding: 8px 26px !important;
  color: #ffffff;
  font-weight: 500;
  color: #ffffff !important;
  font-size: 12px !important;
  border-radius: 10px !important;
}

.live-stream-container .modal-dialog {
  width: 400px;
}

.live-stream-container .modal-dialog .modal-content {
  border-radius: 30px;
}

.live-stream-container .modal-dialog .modal-content .modal-body {
  padding: 0px;
}

.stream-amount-display {
  font-size: 22px;
  font-weight: 600;
  color: #3662ff;
  padding: 8px 16px;
  border-radius: 30px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
}

.message-coin-container {
  z-index: 100;
  top: 30px;
  left: -80px;
  background-color: #ffffff;
  height: auto;
  width: auto;
  /* padding: 0px 12px 12px 12px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 260px;
  border-radius: 30px;
}

.sendCoin-profile-title {
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.sendCoin-display {
  font-size: 22px;
  font-weight: 600;
  color: #3662ff;
  padding: 3px 16px;
  border-radius: 30px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
}

.sendCoin-container {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 220px;
}

.sendCoin-box-active {
  padding: 2px 8px;
  background-color: #0296dd;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
  text-align: center;
  color: #ffffff;
  font-size: 500;
  cursor: pointer;
}

.sendCoin-box {
  padding: 2px 8px;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
  text-align: center;
  color: #9d9d9d;
  cursor: pointer;
}

.sendCoin-box:hover {
  background-color: #1099da9e;
}

.custom-sendCoin-btn {
  background-color: #0296dc !important;
  padding: 1px 16px;
  color: #ffffff;
  font-weight: 500;
  color: #ffffff !important;
  font-size: 12px !important;
}

.sendCoin-custom-input {
  background: #f7f7f7;
  border: 0.725806px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
  height: 37px;
  width: 180px;
  text-align: center;
}
.comment-modal-image-lock {
  background: none !important;
}

.paid-media-input {
  background: #f7f7f7;
  border: 0.725806px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
  height: 37px;
  width: 210px;
  text-align: center;
}

.uploadDoc-paid-preview {
  display: flex;
  justify-content: center;
  height: 166px;
}
.comment-modal-video-preview-main {
  display: flex;
  align-items: center;
}
.audio-duration-count {
  display: flex;
  justify-content: space-between;
}
.video-preview-and-thumbnail-button {
  display: flex;
  position: relative;
}
.paid-packege-upload-file-error {
  color: red;
}
.story-delete-pop-up {
  background: #c3bbbb;
  display: flex;
}
.story-delete-pop-up-option {
  font-size: 16px;
}
.story-delete-pop-over {
  cursor: pointer;
}
.story-delete-pop-over .popover-arrow {
  /* transform: translate(1125.89px, 72.2222px); */
}
.story-delete-pop-over-text {
  margin-bottom: 0px;
  margin: 10px;
  font-weight: 500;
}
.receive-transfer-coin {
  height: 100px;
  width: 165px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #efefef;
  border-radius: 13px 13px 13px 0px;
  position: relative;
}

.receive-transfer-coin-footer {
  font-size: 10px;
  position: absolute;
  width: 165px;
  height: 24px;
  background-color: #dcd3d3;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 13px 0px;
  display: flex;
  padding-left: 10px;
  align-items: center;
}

.send-transfer-coin {
  height: 70px;
  width: 146px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 13px 13px 13px 0px;
  position: relative;
}

.send-transfer-coin-footer {
  width: 176px;
  height: 24px;
  background-color: #8cd9ff;
  position: absolute;
  bottom: -15px;
  left: -15px;
  border-radius: 0px 0px 0px 13px;
  justify-content: start;
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-weight: 400;
}

.send-transfer-coin-footer span {
  font-size: 10px;
}

.send-transfer-coin .amount {
  font-size: 26px;
  font-weight: 700;
}

.receive-transfer-coin .amount {
  font-size: 26px;
  font-weight: 700;
}

.check-icon {
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 4px;
}
.blacklist-setting-image img {
  object-fit: cover !important;
}
.search-profile-loading-spiner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-image-size-validation {
  align-items: center;
}
.file-carousel .carousel-control-prev {
  height: 88%;
}
.file-carousel .carousel-control-next {
  height: 88%;
}
.carousel-main-div .carousel-indicators {
  display: none;
}
.carousel-main-div .carousel-inner {
  max-width: 550px;
}
.awasome-slidere-main-div .awssld__controls__arrow-right {
  height: 10px !important;
}
.awasome-slidere-main-div .awssld__controls__arrow-left {
  height: 10px !important;
}
.awasome-slidere-main-div .awssld__content {
  background-color: #f5f5f5;
}
.awasome-slidere-main-div {
  height: 600px;
}
.comment-modal-loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.delete-button-unset {
  all: unset;
  cursor: pointer;
}
.multiple-image-slider-height {
  overflow: overlay;
  max-height: 595px;
}

.close-chat {
  position: absolute;
  top: -14px;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.edit-form-error {
  font-size: 11px;
  text-align: left;
  font-weight: 400;
  margin-top: 2px;
}
.targeted-thumbnail-file {
  width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.thumbnail-image-erorr {
  color: #e93434 !important;
  font-size: 14px !important;
  color: red !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.poll-post-upload-modal .modal-content {
  border-radius: 20px;
  min-height: 625px;
  border: none;
  width: 450px;
}
.poll-post-upload-modal .modal-content .modal-body {
  padding: 2rem;
}
.poll-option-main-div {
  border: 1px solid rgba(79, 79, 79, 1);
  border-radius: 3px;
  padding: 5px;
}
.poll-option {
  display: flex;
}
.poll-option-image {
  padding: 20px;
}
.poll-option-child-div {
  padding: 5px;
}
.poll-option-image-photo {
  padding: 16px;
}
.poll-option-text-property {
  color: black;
  text-align: center;
  padding-top: 6px;
}
.poll-question-input {
  background-color: transparent;
  outline: 0px;
  border: 0;
  width: 370px;
  height: auto;
  padding: 10px;
  border-bottom: 2px solid rgba(219, 219, 219, 1);
  caret-color: aqua;
  position: relative;
}
.poll-question-input::placeholder {
  color: #b6b6b6;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
}
.active-option-tab {
  background-color: rgba(13, 119, 217, 0.15);
  border-color: rgba(13, 119, 217, 1);
}
.option-upload-image-icon {
  margin: 16px 24px 16px 19px;
}
.upload-image-icon-box {
  border: 1px solid black;
  border-color: rgba(211, 211, 211, 1);
  border-radius: 10px;
  background-color: rgba(242, 242, 242, 1);
}
.option-modal-title {
  font-size: 14px;
  color: rgba(191, 191, 191, 1);
  font-weight: 600;
}
.option-main-div {
  margin-top: 30px;
  justify-content: space-between;
}
.delete-option-element {
  height: 12px;
  margin-top: 14px;
}
.poll-modal-footer-button {
  background-color: rgba(2, 150, 221, 1);
  color: white;
  font-weight: 600;
  border-radius: 12px;
  font-size: 24px;
}
.poll-modal-footer-button button {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
.poll-option-error-message {
  color: red;
  font-size: 14px;
}
.poll-post-question {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
}
.lable-for-image-upload {
  margin: 3px 8px 3px 3px;
}
.post-section-options-label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-left: 13px;
  color: rgba(0, 0, 0, 1);
  display: flex;
  flex-flow: column;
}
.poll-post-option-child-div {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 7px;
  padding: 16px 10px 16px 17px;
  min-width: 522px;
  display: flex;
  align-items: center;
}
.post-poll-image-feed-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  margin-left: 10px;
}
.option-radio-button {
  height: 17px;
  width: 17px;
}
.poll-option-image-preview {
  object-fit: cover;
  border-radius: 6px;
  overflow: hidden;
  margin: 0px;
  height: 100%;
  width: 100%;
}
.upload-image-icon-box-image-preview {
  width: 69px;
  height: 54px;
  border-radius: 6px !important;
}
.post-poll-image-feed-main-div {
  width: 70px;
  height: 70px;
  min-width: 70px;
  margin-right: 5px;
}
.poll-quesion-error {
  font-size: 14px;
  color: red;
}
.poll-image-backgeround {
  background-color: #fff !important;
}

.poll-comment-backgeround {
  border-left: 1px solid #dee2e6;
}
.setting-subtab-nav-link {
  text-decoration: none;
  background-color: transparent;
  color: #0296dd !important;
  border-right: 4px solid #0296dd !important;
  border-radius: 0 !important;
  font-weight: 600;
}

.setting-subtab-nav-link a button {
  color: #0296dd !important;
  font-weight: 600 !important;
}

.container-poll {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-poll input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-poll {
  position: absolute;
  border-radius: 50%;
  top: -2;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: rgba(13, 119, 217, 0.15);
}

/* On mouse-over, add a grey background color */
.container-poll:hover input ~ .checkmark-poll {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-poll input:checked ~ .checkmark-poll {
  background-color: #2196f3;
}

/* Create the checkmark-poll/indicator (hidden when not checked) */
.checkmark-poll:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-poll when checked */
.container-poll input:checked ~ .checkmark-poll:after {
  display: block;
}

/* Style the checkmark-poll/indicator */
.container-poll .checkmark-poll:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.profile-plogout-togen {
  border: 1px solid #ebebeb;
  border-radius: 7px;
  padding-top: 6px;
  background: white;
  padding-left: 5px;
  font-weight: 400;
  color: #212529;
  margin-top: 2px;
  position: absolute;
  z-index: 1000;
  padding-bottom: 6px;
}

/* .profile-plogout-togen li {
  padding-right:76px
} */

.package-video video source {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.mess-img-popUp .modal-content {
  width: auto;
}

.mess-img-popUp .modal-dialog {
  justify-content: center;
}
/* Gift Popover modal */
.gift-popover-container {
  position: absolute;
  z-index: 100;
  top: 100px;
  left: 22px;
  background-color: #ffffff;
  height: auto;
  max-width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 280px;
  border-radius: 30px;
  max-height: 220px;
  overflow-y: auto;
}

.Toastify__toast-container {
  z-index: 200000;
}

/* .agora_video_player {
  width: auto !important;
  position: relative !important;
}
.player > div {
  display: flex !important;
  justify-content: center;
  padding-top: 10px !important;
} */
